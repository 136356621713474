import { Pipe, PipeTransform } from '@angular/core';
import {UserRolePipe} from "./user-role.pipe";
import {ConversationBean, UserBean} from "@chat/common-model";

@Pipe({
  name: 'participants'
})
export class ParticipantsPipe implements PipeTransform {

  constructor(private userRolePipe: UserRolePipe) {
  }

  transform(value: ConversationBean, ...args: any[]): any {
    const users: UserBean[] = args[0];
    let result = "";
    if (value && value.participants) {
      value.participants.forEach(p => {
        if (p) {
          const user = users.find(u => u.id === p.id) || {};
          result = result + "<span>"+this.userRolePipe.transform(p.role) +": " + (user.contactName || p.contactName) + "</span></br>"
          //result = result + "<span>"+this.userRolePipe.transform(p.role) +": " + p.contactName + "</span></br>"
        }
      })
    }
    return result;
  }

}
