import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import * as moment from "moment";
import * as jwt_decode from "jwt-decode";
import {map, tap} from "rxjs/operators";
import {Url} from "../url";
import {LoginBean, TokenBean, UserBean} from "@chat/common-model";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private static STORAGE_TOKEN_KEY = 'id_token';
  private static STORAGE_EXPIRES_KEY = 'expires_at';

  private token: string;
  private parsedToken: any;
  private expiresAt: moment.Moment;

  constructor(private http: HttpClient) {

  }

  login(loginBean: LoginBean): Observable<any> {
    return this.http.post<TokenBean>(Url.API_LOGIN, loginBean).pipe(map<TokenBean, any>(tokenBean => {
      this.setSession(tokenBean);
      return this.parsedToken;
    }));
  }

  loginWialon(loginBean: LoginBean): Observable<any> {
    return this.http.post<TokenBean>(Url.API_LOGIN, loginBean).pipe(map<TokenBean, any>(tokenBean => {
      this.setSession(tokenBean);
      return this.parsedToken;
    }));
  }

  logout() {
    this.token = null;
    this.expiresAt = null;
    localStorage.removeItem(AuthService.STORAGE_TOKEN_KEY);
    localStorage.removeItem(AuthService.STORAGE_EXPIRES_KEY);
  }

  public loadToken(): Observable<number> {
    const that = this;
    return new Observable((observer) => {
      const token = this.getParsedToken();
      const expiresAt: moment.Moment = this.getExpiresAt();
      console.log(token);
      console.log(expiresAt && expiresAt.format());
      if (token && expiresAt && expiresAt.isAfter(moment().utc())) {
        observer.next(0);
        observer.complete();
      } else {
        observer.next(-1);
        observer.complete();
      }
    });
  }

  public loadUserProfile(): Observable<UserBean> {
    const that = this;
    return new Observable((observer) => {
      const url = Url.API_USER_PROFILE;
      const headers = new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'bearer ' + this.token
      });
      that.http.get<UserBean>(url, {headers: headers}) .subscribe(profile => {
        observer.next(profile);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  private setSession(tokenBean: TokenBean) {
    const expiresAt = moment().add(tokenBean.expires_in,'second');
    this.token = tokenBean.access_token;
    this.parsedToken = this.decodeToken(tokenBean.access_token);
    this.expiresAt = expiresAt;
    localStorage.setItem(AuthService.STORAGE_TOKEN_KEY, this.token);
    localStorage.setItem(AuthService.STORAGE_EXPIRES_KEY, JSON.stringify(expiresAt.valueOf()) );
  }

  private getParsedToken(): any {
    if (this.token) return this.token;
    const token = localStorage.getItem(AuthService.STORAGE_TOKEN_KEY);
    if (token) {
      const parsedToken = this.decodeToken(token);
      if (parsedToken) {
        this.token = token;
        this.parsedToken = parsedToken;
        return parsedToken;
      }
    }
    return null;
  }

  public getToken(): string {
    if (!this.token) {
      this.getParsedToken();
    }
    return this.token;
  }

  private getExpiresAt(): moment.Moment {
    if (this.expiresAt) return this.expiresAt;
    const expiresAt = localStorage.getItem(AuthService.STORAGE_EXPIRES_KEY);
    if (expiresAt) {
      this.expiresAt = moment(parseInt(expiresAt));
      return this.expiresAt;
    }
    return null;
  }

  private decodeToken(token: string): any {
    try{
      return jwt_decode(token);
    }
    catch(Error){
      return null;
    }
  }
}
