import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SessionService} from "../../common/auth/session.service";
import {StatusService} from "../../service/status.service";
import {ConfigService} from "../../service/config.service";
import {ChatViewType} from "../../common/constant/config";
import {PushNotificationsService} from "ng-push";
import {Subscription} from "rxjs";
import {MessageListBean, MessageType, UserBean} from "@chat/common-model";
import {UserService} from "../../service/admin/user.service";
import {ConversationService} from "../../service/conversation.service";

export interface Section {
  name: string;
  updated: Date;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

  username: string;
  @ViewChild('divToScroll', {static: false}) divToScroll: ElementRef;
  ChatViewType: any = ChatViewType;

  newMessagesSubscription: Subscription;

  constructor(private sessionService: SessionService,
              public configService: ConfigService,
              private conversationService: ConversationService,
              private statusService: StatusService,
              private userService: UserService,
              private pushNotificationsService: PushNotificationsService) { }

  ngOnInit() {
    this.username = this.sessionService.user && this.sessionService.user.fullName || "";
    this.statusService.start();
    this.pushNotificationsService.requestPermission();
    this.userService.getList().subscribe((users: UserBean[]) => {
      this.userService.users = users;
    });

    this.newMessagesSubscription = this.statusService.newMessagesEmitter.subscribe((messageList: MessageListBean) => {
      if (messageList && messageList.messages) {
        for (const message of messageList.messages) {
          const options = { //set options
            body: message.message
          };
          this.pushNotificationsService.create('Новое сообщение', options).subscribe( //creates a notification
            res => console.log(res),
            err => console.log(err)
          );
        }
      }
    });
    this.conversationService.showAlphabet = true;
  }

  ngOnDestroy(): void {
    this.statusService.stop();
    this.newMessagesSubscription.unsubscribe();
    this.conversationService.showAlphabet = false;
  }

}
