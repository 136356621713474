import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-link-user',
  templateUrl: './link-user.component.html',
  styleUrls: ['./link-user.component.scss']
})
export class LinkUserComponent implements OnInit {

  name: string;

  constructor(public dialogRef: MatDialogRef<LinkUserComponent>) { }

  ngOnInit() {
  }

  onClickLinkName() {
    this.dialogRef.close(this.name);
  }
}
