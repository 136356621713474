import {Component, Inject, Input, OnInit} from '@angular/core';
import {ConversationBean, getConversationName, UserBean} from "@chat/common-model";
import {ConversationService} from "../../../service/conversation.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SessionService} from "../../auth/session.service";

export interface SelectConversationDataType {
  title: string;
  user: UserBean;
  conversations: ConversationBean[];
}

@Component({
  selector: 'app-select-conversation',
  templateUrl: './select-conversation.component.html',
  styleUrls: ['./select-conversation.component.scss']
})
export class SelectConversationComponent implements OnInit {

  @Input() title: string = 'Выберите чат';

  user: UserBean;
  conversations: ConversationBean[] = [];
  filteredConversations: ConversationBean[] = [];
  loaded: boolean = false;

  selected: ConversationBean;

  search: string;

  getConversationName: any = getConversationName;

  constructor(private conversationService: ConversationService,
              private sessionService: SessionService,
              public dialogRef: MatDialogRef<SelectConversationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: SelectConversationDataType) {
    this.title = data.title;
    if (data.conversations) {
      this.conversations = data.conversations;
      this.filteredConversations = data.conversations;
      this.loaded = true;
    }
    this.user = data.user || sessionService.user;
  }

  ngOnInit() {
    if (!this.loaded) {
      this.getConversations();
    }
  }

  getConversations() {
    this.loaded = false;
    this.conversationService.getConversationList().subscribe((list: ConversationBean[]) => {
      this.conversations = list;
      this.filteredConversations = list;
      this.sort();
    }, () => {}, () => { this.loaded = true; });
  }

  sort() {
    this.conversations.sort((a, b) => {
      const nameA = a.name ? a.name.toUpperCase() : '';
      const nameB = b.name ? b.name.toUpperCase() : '';
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  onClickClearSearch() {
    this.search = null;
    this.onChangeSearch();
  }

  onChangeSearch() {
    if (this.search) {
      this.filteredConversations = this.conversations.filter(option => new RegExp(this.search, 'gi')
        .test(option.name));
    } else {
      this.filteredConversations = this.conversations;
    }
  }

  onClickSelectConversation(conversation: ConversationBean) {
    this.selected = conversation;
  }

  onClickSelect() {
    this.dialogRef.close(this.selected);
  }
}
