import { Component, OnInit } from '@angular/core';
import {HttpService} from "../../../common/http/http.service";
import {CompanyBean} from "@chat/common-model";
import {Url} from "../../../common/url";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private httpService: HttpService) { }

  ngOnInit() {
  }

  onClickFillCompany() {
    this.httpService.get<any>({url: '/api/v1/admin/fill-company'}).subscribe();
  }
}
