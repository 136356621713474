import {EventEmitter, Injectable} from '@angular/core';
import {ChatViewType} from "../common/constant/config";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  chatView: ChatViewType = ChatViewType.Normal;
  public chatViewEmitter: EventEmitter<ChatViewType>;

  constructor() {
    this.chatViewEmitter = new EventEmitter<ChatViewType>();
  }

  setChatView(chatView: ChatViewType) {
    this.chatView = chatView;
    this.chatViewEmitter.emit(chatView);
  }
}
