import {UserRole} from "@chat/common-model";

export class Url {

  public static API = 'api/v1';
  public static ROUTE_ROOT = '';

  public static API_AUTH = Url.API + '/auth';
  public static API_ADMIN = Url.API + '/admin';
  public static API_LOGIN = Url.API_AUTH + '/login';
  public static API_LOGOUT = Url.API_AUTH + '/logout';

  public static API_ADMIN_COMPANIES = Url.API_ADMIN + '/companies';
  public static API_ADMIN_USERS = Url.API_ADMIN + '/users';

  public static API_TRIALS = Url.API + '/trials';
  public static API_TRIALS_BY_COMPANY = (id) => Url.API_TRIALS + '/' + id;

  public static API_USERS = Url.API + '/users';
  public static API_USER_PROFILE = Url.API_USERS + '/profile';
  public static API_USERS_STATUSES = Url.API_USERS + '/statuses';
  public static API_USER = (id) => Url.API_USERS + '/' + id;
  public static API_USER_EXTERNAL_USER = (id) => Url.API_USERS + '/external-user/' + id;
  public static API_USER_EXTERNAL = (id) => Url.API_USERS + '/external/' + id;
  public static API_USER_LOGS = (id) => Url.API_USER(id) + '/logs';
  public static API_LOGS = Url.API + '/logs';
  public static API_USER_LOGS_LIST = (id) => Url.API_LOGS + '/users/' + id;
  public static API_CONVERSATIONS = Url.API + '/conversations';
  public static API_CONVERSATIONS_FOR_USER = (uid) => Url.API_CONVERSATIONS + '/users/' + uid;
  public static API_CONVERSATION = (cid) => Url.API_CONVERSATIONS + '/' + cid;
  public static API_MESSAGES = (cid) => Url.API_CONVERSATION(cid) + '/messages';
  public static API_MESSAGE = (cid, mid) => Url.API_MESSAGES(cid) + '/' + mid;
  public static API_MESSAGES_NEW = (cid) => Url.API_MESSAGES(cid) + '/new';
  public static API_MESSAGES_UNSEEN = (cid) => Url.API_MESSAGES(cid) + '/unseen';
  public static API_FORWARD_MESSAGE = (cid) => Url.API_MESSAGES(cid) + '/forward';
  public static API_ALL_MESSAGES = Url.API + '/messages';
  public static API_USER_MESSAGES = Url.API + '/messages/user';
  public static API_MESSAGES_FILE = (cid) => Url.API_MESSAGES(cid) + '/file';
  public static API_MESSAGES_SYNC = (cid) => Url.API_MESSAGES(cid) + '/sync';
  public static API_MESSAGES_BATCH_SEEN = (cid) => Url.API_MESSAGES(cid) + '/batch/seen';
  public static API_STATUSES = Url.API + '/statuses/web';

  public static API_INFO = Url.API + '/info';
  public static API_INFO_LAST_VERSION = Url.API_INFO + '/last-version';

  public static API_CONTACTS = Url.API + '/contacts';


  public static ROUTE_SIGNIN = Url.ROUTE_ROOT + '/signin';
  public static ROUTE_FORBIDDEN = Url.ROUTE_ROOT + '/forbidden';

  public static ROUTE_USERS = Url.ROUTE_ROOT + '/users';
  public static ROUTE_USER_NEW = Url.ROUTE_USERS + '/new';
  public static ROUTE_HISTORY = Url.ROUTE_ROOT + '/history';
  public static ROUTE_HISTORY_WITH_COMPANY = (id) => Url.ROUTE_HISTORY + '?companyId=' + id;
  public static ROUTE_USER = (id) => Url.ROUTE_USERS + '/' + id;
  public static ROUTE_USER_CONVERSATION = (id, cid) => Url.ROUTE_USER(id) + '/conversations/' + cid;

  public static ROUTE_TRIAL = Url.ROUTE_ROOT + '/trial';
  public static ROUTE_TRIAL_BY_COMPANY = (id) =>  Url.ROUTE_TRIAL + '/' + id;



  public static API_ADMIN_COMPANY = (id) => Url.API_ADMIN_COMPANIES + '/' + id;
  public static API_ADMIN_COMPANY_USERS = (id) => Url.API_ADMIN_COMPANY(id) + '/users';
  public static API_ADMIN_USER = (id) => Url.API_ADMIN_USERS + '/' + id;
  public static API_ADMIN_USER_EXTERNAL = (id) => Url.API_ADMIN_USERS + '/external/' + id;
  public static API_ADMIN_USER_CONVERSATIONS = (id) => Url.API_ADMIN_USER(id) + '/conversations';
  public static API_ADMIN_USER_CONVERSATION = (id, cid) => Url.API_ADMIN_USER_CONVERSATIONS(id) + '/' + cid;
  public static API_ADMIN_USER_LOGS_REQUEST = (id) => Url.API_ADMIN_USER(id) + '/logs';

  public static ROUTE_ADMIN = Url.ROUTE_ROOT + '/admin';
  public static ROUTE_ADMIN_COMPANIES = Url.ROUTE_ADMIN;
  public static ROUTE_ADMIN_COMPANY_NEW = Url.ROUTE_ADMIN_COMPANIES + '/new';
  public static ROUTE_ADMIN_USERS = Url.ROUTE_ADMIN + '/users';
  public static ROUTE_ADMIN_COMPANY = (id) => Url.ROUTE_ADMIN_COMPANIES + '/' + id;
  public static ROUTE_ADMIN_COMPANY_USERS = (id) => Url.ROUTE_ADMIN_COMPANY(id) + '/users';
  public static ROUTE_ADMIN_USER = (id) => Url.ROUTE_ADMIN_USERS + '/' + id;
  public static ROUTE_ADMIN_USER_CONVERSATION = (id, cid) => Url.ROUTE_ADMIN_USER(id) + '/conversations/' + cid;
  public static ROUTE_ADMIN_COMPANY_USER_NEW = (companyId) => Url.ROUTE_ADMIN_COMPANY_USERS(companyId) + '/new';

  public static getRouteByRole = (role) => {
    if (role === UserRole.SUPERADMIN) {
      return  Url.ROUTE_ADMIN;
    } else if (role === UserRole.ADMIN) {
      return Url.ROUTE_USERS;
    } else if (role === UserRole.SUPERVISOR) {
      return Url.ROUTE_HISTORY;
    } else {
      return Url.ROUTE_ROOT;
    }
  }

}
