import { Injectable } from '@angular/core';
import { TrialBean, UserBean } from '@chat/common-model';
import { Observable } from 'rxjs';
import { Url } from '../common/url';
import { HttpService } from '../common/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class TrialService {

  constructor(private httpService: HttpService) { }

  public generate(trial: TrialBean): Observable<{} | UserBean[]> {
    return this.httpService.post<UserBean[]>({url: Url.API_TRIALS, body: trial});
  }

  public get(companyName: string): Observable<{} | UserBean[]> {
    return this.httpService.get<UserBean[]>({url: Url.API_TRIALS_BY_COMPANY(companyName)});
  }

}
