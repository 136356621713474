import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ConversationBean,
  ConversationType,
  Filter,
  FilterMethod,
  MessageBean,
  MessageDirectionType,
  MessageListBean, MessagePushStatusType,
  MessageStatusType,
  MessageType,
  UserBean
} from "@chat/common-model";
import {MessageFileType, MessageService} from "../../../../service/message.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogConversationViewImageComponent} from "../../dialog-conversation/dialog-conversation-view-image/dialog-conversation-view-image.component";
import {HistoryVoiceComponent} from "../../../admin/history/voice/history-voice.component";
import {UserService} from "../../../../service/admin/user.service";
import {MatTable} from "@angular/material/table";
import {Observable, Subscription} from "rxjs";
import {ConversationService} from "../../../../service/conversation.service";
import {SessionService} from "../../../../common/auth/session.service";
import {StatusService} from "../../../../service/status.service";
import {ReplyComponent} from "../../reply/reply.component";
import {SelectConversationComponent} from "../../../../common/dialog/select-conversation/select-conversation.component";
import {PostMessageDialogComponent} from "../../../../common/dialog/post-message-dialog/post-message-dialog.component";

@Component({
  selector: 'app-table-message-table',
  templateUrl: './table-message-table.component.html',
  styleUrls: ['./table-message-table.component.scss']
})
export class TableMessageTableComponent implements OnInit, OnDestroy {

  loadedUsers: UserBean[] = [];
  conversations: ConversationBean[] = [];
  messages: MessageBean[] = [];
  messageDisplayedColumns: string[] = ['id', 'status', 'author', 'participant', 'created', 'message', 'attach', 'actions'];
  @ViewChild(MatTable, {static: true}) table: MatTable<MessageBean>;
  from: string;
  status: MessageStatusType;

  MessageType: any = MessageType;
  MessageStatusType: any = MessageStatusType;
  MessagePushStatusType: any = MessagePushStatusType;

  showMoreButton: boolean = false;
  loading: boolean = false;

  currentConversationSubscription: Subscription;
  typeMessageSubscription: Subscription;
  newMessagesSubscription: Subscription;
  postMessageSubscription: Subscription;

  selectedConversation: ConversationBean;
  seenInProgress: MessageBean[] = [];

  constructor(private messageService: MessageService,
              private userService: UserService,
              private conversationService: ConversationService,
              public sessionService: SessionService,
              private statusService: StatusService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.getUsers();
    this.currentConversationSubscription = this.conversationService.currentConversationEmitter.subscribe((current: ConversationBean) => {
      if (current) {
        this.messageService.tableFilters.removeFilter("type");
        const filter = this.messageService.tableFilters.getFilter("conversation");
        if (!filter) {
          this.messageService.tableFilters.filters.push(Filter.getInstance("conversation", current.id, FilterMethod.EQUALS));
        } else {
          filter.value = current.id;
        }
      } else {
        this.messageService.tableFilters.removeFilter("conversation");
      }
      this.selectedConversation = current;
      this.getMessages(true);
    });
    this.typeMessageSubscription = this.messageService.typeMessageEmitter.subscribe((type: MessageDirectionType) => {
      this.messageService.tableFilters.removeFilter("conversation");
      if (type === MessageDirectionType.ALL) {
        this.messageService.tableFilters.removeFilter("type");
      } else {
        const filter = this.messageService.tableFilters.getFilter("type");
        if (!filter) {
          this.messageService.tableFilters.filters.push(Filter.getInstance("type", type, FilterMethod.EQUALS));
        } else {
          filter.value = type;
        }
      }
      this.getMessages(true);
    });
    this.newMessagesSubscription = this.statusService.newMessagesEmitter.subscribe((messageList: MessageListBean) => {
      this.getMessages(true);
    });
    this.postMessageSubscription = this.messageService.postMessageEmitter.subscribe((message: MessageBean) => {
      this.getMessages(true);
    });
  }

  ngOnDestroy(): void {
    this.currentConversationSubscription.unsubscribe();
    this.typeMessageSubscription.unsubscribe();
    this.newMessagesSubscription.unsubscribe();
    this.postMessageSubscription.unsubscribe();
  }

  getUsers() {
    this.userService.getList().subscribe((list: UserBean[]) => {
      this.loadedUsers = list;
      this.getMessages();
    })
  }

  getMessages(reset: boolean = false) {
    if (reset) {
      this._resetFrom();
    }
    this.messageService.getUserMessageList(this.from, 20, this.messageService.tableFilters).subscribe((list: MessageListBean) => {
      if (reset) {
        this.messages = [];
      }
      if (list) {
        list.messages.forEach(mb => {
          const author = this.loadedUsers.find(u => u.id === mb.author.id);
          if (author) {
            mb.author = author;
          }
          this._getParticipant(mb, author).subscribe(p => {
            mb["participant"] = p;
          });
          if (mb.message) {
            mb.message = mb.message.replace(/(?:\r\n|\r|\n)/g, '<br />');
          }
          this.messages.push(mb);
        });
        this.from = list.messages[list.messages.length - 1].created;
        this.showMoreButton = list.hasMore;
        this.table.renderRows();
      }
    })
  }

  onClickShowMore() {
    this.getMessages();
  }

  onClickResource(message: MessageBean) {
    if (message.url) {
      if (message.type === MessageType.FILE) {
        this.loading = true;
        this.messageService.getFile(message).subscribe(result => {
          this.loading = false;
          const link = document.createElement('a');
          link.style.display = 'none';
          document.body.appendChild(link);


          const blob = new Blob([result], {type: message.mimeType});
          link.href = URL.createObjectURL(blob);
          link.download = message.originalName || message.id;
          link.click();
        }, () => {
          this.loading = false;
          this.snackBar.open("Ошибка загрузки файла");
        });
      } else if (message.type === MessageType.IMAGE) {
        this.loading = true;
        this.messageService.getFile(message).subscribe(resultJpeg => {
          this.loading = false;
          this._openImage(message, resultJpeg, "image/jpeg");
        }, err => {
          this.messageService.getFile(message, MessageFileType.ORIGIN).subscribe(result => {
            this.loading = false;
            this._openImage(message, result, message.mimeType);
          }, () => {
            this.loading = false;
            this.snackBar.open("Ошибка загрузки изображения");
          }, () => { this.loading = false; });
        });
      } else if (message.type === MessageType.VOICE) {
        this._openVoice(message);
      }
    }
  }

  private _openImage(message: MessageBean, image, mimeType) {
    const config = new MatDialogConfig();
    config['data'] = {message: message, image: new Blob([image], {type: mimeType}), type: mimeType};
    config.height = '100%';
    config.width = '100%';
    config.maxHeight = '100%';
    config.maxWidth = '100%';

    config.panelClass = 'transparent';
    const dialogRef = this.dialog.open(DialogConversationViewImageComponent, config);
  }

  private _openVoice(message: MessageBean) {
    const config = new MatDialogConfig();
    config['data'] = {message: message};

    config.panelClass = 'transparent';
    const dialogRef = this.dialog.open(HistoryVoiceComponent, config);
  }

  private _getParticipant(message: MessageBean, author: UserBean): Observable<string> {
    return new Observable<string>(observer => {
      const me = this.sessionService.user;
      let c = this.conversations.find(c => c.id === message.conversation.id);
      let participant;
      if (c) {
        if (c.type === ConversationType.GROUP) {
          observer.next(c.name || "Группа");
        } else {
          participant = c.participants.find(p => p.id !== message.author.id);
          if (participant) {
            observer.next(participant.contactName);
          } else {
            observer.next(me.contactName);
          }
        }
        observer.complete();
      } else {
        this.conversationService.get(message.conversation.id).subscribe((conversation: ConversationBean) => {
          this.conversations.push(conversation);
          if (conversation.type === ConversationType.GROUP) {
            observer.next(conversation.name || "Группа");
          } else {
            participant = conversation.participants.find(p => p.id !== message.author.id);
            if (participant) {
              observer.next(participant.contactName);
            } else {
              observer.next(me.contactName);
            }
          }
          observer.complete();
        });
      }
    });
  }

  _resetFrom() {
    //this.from = moment().utc().toISOString();
    this.from = null;
  }

  onChangeStatus() {
    if (this.status) {
        const filter = this.messageService.tableFilters.getFilter("status");
        if (!filter) {
          this.messageService.tableFilters.filters.push(Filter.getInstance("status", this.status, FilterMethod.EQUALS));
        } else {
          filter.value = this.status;
        }
    } else {
      this.messageService.tableFilters.removeFilter("status");
    }
    this.getMessages(true);
  }

  onClickDownloadResource(message: MessageBean) {
    if (message.url) {
      this.loading = true;
      this.messageService.getFile(message).subscribe(result => {
        this.loading = false;
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        const blob = new Blob([result], {type: this.messageService.getFileMimeType(message)});
        link.href = URL.createObjectURL(blob);
        link.download = message.originalName || message.id;
        link.click();
      }, () => {
        this.loading = false;
        this.snackBar.open("Ошибка загрузки файла");
      });
    }
  }

  onClickSeen(message: MessageBean) {
    if (!message['seenInProgress']) {
      message['seenInProgress'] = true;
      this.messageService.setSeen(message.conversation.id, [message]).subscribe(() => {
        const msg = this.messages.find(m => m.id === message.id);
        if (msg) {
          msg.status = MessageStatusType.SEEN;
        }
        message['seenInProgress'] = false;
        this.table.renderRows();
      });
    }
  }

  onClickRefresh() {
    this.getMessages(true);
  }


  onClickReply(replyTo: MessageBean) {
    const config = new MatDialogConfig();
    config['data'] = {replyTo};
    config.width = '600px';

    const dialogRef = this.dialog.open(ReplyComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onClickSeen(replyTo);
      }
    });
  }

  getMessage(mb: MessageBean) {
    return mb.replyTo ? `<span class="reply-message">${mb.replyTo.message}</span>${mb.message}` : mb.message;
  }

  onClickSend() {
    const config = new MatDialogConfig();
    config.data = {title: 'Выберите чат, в который надо отправить сообщение'};
    const dialogRef = this.dialog.open(SelectConversationComponent, config);
    dialogRef.afterClosed().subscribe((conversation: ConversationBean) => {
      if (conversation) {
        const configPost = new MatDialogConfig();
        configPost.data = {conversation: conversation};
        this.dialog.open(PostMessageDialogComponent, configPost);
      }
    });
  }
}
