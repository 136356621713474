import {EventEmitter, Injectable} from '@angular/core';
import {HttpService} from "../../common/http/http.service";
import {Observable} from "rxjs";
import {Url} from "../../common/url";
import {SessionService} from "../../common/auth/session.service";
import {
  CompanyBean,
  ConversationBean,
  MessageBean,
  MessageListBean, MessageType,
  ResponseStatusBean,
  UserBean, UserLogsBean,
  UserRole
} from "@chat/common-model";
import {MessageFileType} from "../message.service";
import {UserExternalInfoBean} from "../../../../projects/common-model/src/lib/bean";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public users: UserBean[] = [];
  public conversationCreatedEmitter: EventEmitter<ConversationBean>;

  constructor(private httpService: HttpService, private sessionService: SessionService) {
    this.conversationCreatedEmitter = new EventEmitter<ConversationBean>();
  }

  public getListByCompany(companyId: string): Observable<{} | UserBean[]> {
    return this.httpService.get<UserBean[]>({url: Url.API_ADMIN_COMPANY_USERS(companyId)});
  }

  public get(id: string): Observable<{} | UserBean> {
    if (!id) {
      return new Observable((observer) => {
        observer.next({});
        observer.complete();
      });
    } else {
      return this.httpService.get<UserBean>({url: this.isSuperAdmin() ? Url.API_ADMIN_USER(id) : Url.API_USER(id)});
    }
  }

  public getExternal(id: string, quiet: boolean = false): Observable<{} | UserBean> {
    if (!id) {
      return new Observable((observer) => {
        observer.next({});
        observer.complete();
      });
    } else {
      return this.httpService.get<UserBean>({
        url: this.isSuperAdmin() ? Url.API_ADMIN_USER_EXTERNAL(id) : Url.API_USER_EXTERNAL(id),
        quiet
      });
    }
  }

  public getList(companyId?: string): Observable<{} | UserBean[]> {
    const search = {};
    if (companyId) search["company"] = companyId;
    return this.httpService.get<CompanyBean[]>({search: search, url: Url.API_USERS});
  }

  public save(user: UserBean): Observable<{} | UserBean> {
    return user.id ? this.update(user) : this.create(user);
  }

  public create(user: UserBean): Observable<{} | UserBean> {
    return this.httpService.post<CompanyBean>({url: this.isSuperAdmin() ? Url.API_ADMIN_USERS : Url.API_USERS, body: user});
  }

  public update(user: UserBean): Observable<{} | UserBean> {
    const {id} = user;
    return this.httpService.put<CompanyBean>({url: this.isSuperAdmin() ? Url.API_ADMIN_USER(id) : Url.API_USER(id), body: user});
  }

  public remove(user: UserBean): Observable<{} | ResponseStatusBean> {
    const {id} = user;
    return this.httpService.del<ResponseStatusBean>({url: this.isSuperAdmin() ? Url.API_ADMIN_USER(id) : Url.API_USER(id)});
  }

  public updateExternalUser(user: UserBean, externalUsers: UserExternalInfoBean): Observable<{} | UserBean> {
    const {id} = user;
    return this.httpService.put<CompanyBean>({url: Url.API_USER_EXTERNAL_USER(id), body: externalUsers});
  }


  public getConversationList(id: string): Observable<{} | ConversationBean[]> {
    return this.httpService.get<ConversationBean[]>({url: this.isSuperAdmin() ? Url.API_ADMIN_USER_CONVERSATIONS(id): Url.API_CONVERSATIONS_FOR_USER(id)});
  }

  public getConversation(id: string, cid: string): Observable<{} | ConversationBean> {
    return this.httpService.get<ConversationBean>({url: this.isSuperAdmin() ? Url.API_ADMIN_USER_CONVERSATION(id, cid) : Url.API_CONVERSATION(cid)});
  }

  public saveConversation(id: string, conversation: ConversationBean): Observable<{} | ConversationBean> {
    return conversation.id ? this.updateConversation(id, conversation) : this.createConversation(id, conversation);
  }

  public createConversation(id: string, conversation: ConversationBean): Observable<{} | ConversationBean> {
    return this.httpService.post<ConversationBean>({
      url:  this.isSuperAdmin() ? Url.API_ADMIN_USER_CONVERSATIONS(id): Url.API_CONVERSATIONS,
      body: conversation
    });
  }

  public updateConversation(id: string, conversation: ConversationBean): Observable<{} | ConversationBean> {
    const cid = conversation.id;
    return this.httpService.put<ConversationBean>({
      url: this.isSuperAdmin() ? Url.API_ADMIN_USER_CONVERSATION(id, cid) : Url.API_CONVERSATION(cid),
      body: conversation
    });
  }

  public removeConversation(user: UserBean, conversation: ConversationBean): Observable<{} | ResponseStatusBean> {
    const id = user.id;
    const cid = conversation.id;
    return this.httpService.del<ResponseStatusBean>({
      url: this.isSuperAdmin() ? Url.API_ADMIN_USER_CONVERSATION(id, cid) : Url.API_CONVERSATION(cid)
    });
  }

  public logsRequest(user: UserBean): Observable<{} | ResponseStatusBean> {
    const id = user.id;
    return this.httpService.put<ResponseStatusBean>({
      url: Url.API_USER_LOGS(id),
      body: {},
    });
  }

  public logsList(user: UserBean): Observable<{} | UserLogsBean[]> {
    const id = user.id;
    return this.httpService.get<UserLogsBean[]>({
      url: Url.API_USER_LOGS_LIST(id)
    });
  }

  public getLogsFile(userLogs: UserLogsBean): Observable<{} | any> {
    return this.httpService.get<any>({url: userLogs.url, responseType: 'arraybuffer'});
  }

  private isSuperAdmin(): boolean {
    return this.sessionService.user && this.sessionService.user.role && this.sessionService.user.role === UserRole.SUPERADMIN;
  }
}
