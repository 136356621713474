import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {MessageBean, MessageType} from "@chat/common-model";

export interface FileMessage {
  file: File,
  preview?: any,
  message: MessageBean
}

@Component({
  selector: 'app-dialog-file-dialog',
  templateUrl: './dialog-file-dialog.component.html',
  styleUrls: ['./dialog-file-dialog.component.scss']
})
export class DialogFileDialogComponent implements OnInit {

  messageText: string;
  messages: Set<FileMessage> = new Set<FileMessage>();

  MessageType: any = MessageType;

  constructor(public dialogRef: MatDialogRef<DialogFileDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Set<File>) {
  }

  ngOnInit() {
    this.data.forEach(d => {
      const fm: FileMessage = {
        file: d,
        message: {
          type: this.getType(d)
        }
      };
      if (fm.message.type === MessageType.IMAGE) {
        this.preview(fm);
      }
      this.messages.add(fm);
    });
  }

  getType(f: File): MessageType {
    var mimeType = f.type;
    if (mimeType.match(/image\/*/) == null) {
      return MessageType.FILE;
    } else {
      return MessageType.IMAGE;
    }
  }

  preview(fm: FileMessage) {
    let reader = new FileReader();
    reader.onload = (_event) => {
      fm.preview = reader.result;
    }
    reader.readAsDataURL(fm.file);
  }

  onClickSend() {
    this.messages.forEach(m => {
      m.message.message = this.messageText;
    });
    this.dialogRef.close(this.messages);
  }
}
