import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ConversationBean, UserBean, UserRole} from "@chat/common-model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../../../../../service/admin/user.service";

@Component({
  selector: 'app-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss']
})
export class ParticipantsComponent implements OnInit {

  usersLoaded: boolean = false;
  allUsers: UserBean[];
  filteredUsers: UserBean[];

  search: string;

  conversation: ConversationBean;

  @ViewChild('selectedForGroupConversation', { static: false }) selectedForGroupConversation: any;

  constructor(public dialogRef: MatDialogRef<ParticipantsComponent>,
              private userService: UserService,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.conversation = data.conversation;
  }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.userService.getListByCompany(this.data.companyId).subscribe((users: UserBean[]) => {
      this._getUsers(users);
    })
  }

  _getUsers(users: UserBean[]) {
    this.allUsers = users.filter(u => [UserRole.OPERATOR, UserRole.DRIVER].includes(u.role)
      && !(this.conversation.participants && this.conversation.participants.find(p => p.id === u.id))
      && !(this.conversation.spectators && this.conversation.spectators.find(p => p.id === u.id)));
    this.filteredUsers = this.allUsers;
    this.usersLoaded = true;
  }

  onClickCreateConversation(list: any) {
    if (list && list.selectedOptions && list.selectedOptions.selected.length > 0) {
      let participants = list.selectedOptions.selected.map(selected => selected.value);
      this.dialogRef.close(participants);
    }
  }

  onClickClearSearch() {
    this.search = null;
    this.onChangeSearch();
  }

  onChangeSearch() {
    if (this.search) {
      this.filteredUsers = this.allUsers.filter(option => new RegExp(this.search, 'gi')
        .test(option.fullName + " " + option.contactName));
    } else {
      this.filteredUsers = this.allUsers;
    }
  }

}
