import {Component, Inject, OnInit} from '@angular/core';
import {ConversationBean} from "@chat/common-model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-post-message-dialog',
  templateUrl: './post-message-dialog.component.html',
  styleUrls: ['./post-message-dialog.component.scss']
})
export class PostMessageDialogComponent implements OnInit {

  conversation: ConversationBean;

  constructor(public dialogRef: MatDialogRef<PostMessageDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.conversation = data.conversation;
  }

  ngOnInit() {
    this.dialogRef.updateSize('600px');
  }

  onMessageSent() {
    this.dialogRef.close();
  }
}
