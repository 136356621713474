import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ConversationBean, MessageBean, MessageStatusType, UserBean, UserRole} from "@chat/common-model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../../../../../service/admin/user.service";
import {SessionService} from "../../../../../common/auth/session.service";
import {MessageService} from "../../../../../service/message.service";

interface UserStatus {
  user?: UserBean;
  status?: MessageStatusType;
}

@Component({
  selector: 'app-dialog-conversation-message-info',
  templateUrl: './dialog-conversation-message-info.component.html',
  styleUrls: ['./dialog-conversation-message-info.component.scss']
})
export class DialogConversationMessageInfoComponent implements OnInit {

  usersLoaded: boolean = false;
  allUsers: UserStatus[] = [];
  message: MessageBean;
  conversation: ConversationBean;

  @ViewChild('selectedForGroupConversation', { static: false }) selectedForGroupConversation: any;

  constructor(public dialogRef: MatDialogRef<DialogConversationMessageInfoComponent>,
              private userService: UserService,
              private messageService: MessageService,
              private sessionService: SessionService,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.message = data.message;
    this.conversation = data.conversation;
  }

  ngOnInit() {
    if (this.message.groupStatus) {
      this.messageService.getMessage(this.conversation.id, this.message.id).subscribe((message) => {
        for (const userId of Object.keys(message.groupStatus)) {
          if (userId !== this.sessionService.user.id) {
            const user = this.conversation.participants.find(p => p.id === userId);
            if (user) {
              this.allUsers.push({
                user: user,
                status: message.groupStatus[userId]
              });
            }
          }
        }
        this.usersLoaded = true;
      })
    }
  }

}
