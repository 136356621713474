import {Component, Inject, Input, OnInit} from '@angular/core';
import {MessageBean} from "@chat/common-model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-history-voice',
  templateUrl: './history-voice.component.html',
  styleUrls: ['./history-voice.component.scss']
})
export class
HistoryVoiceComponent implements OnInit {

  message: MessageBean;

  constructor(public dialogRef: MatDialogRef<HistoryVoiceComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
  }

  ngOnInit() {
  }

  onClickClose() {
    this.dialogRef.close();
  }
}
