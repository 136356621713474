import { Pipe, PipeTransform } from '@angular/core';
import {MessageStatusType} from "@chat/common-model";
import {MessageType} from "../../../../projects/common-model/src/lib/constant";

@Pipe({
  name: 'msgTypeToIcon'
})
export class MsgTypeToIconPipe implements PipeTransform {

  transform(value: MessageType, ...args: any[]): any {
    let result;
    switch (value) {
      case MessageType.TEXT:
        result = "chat";break;
      case MessageType.IMAGE:
        result = "image";break;
      case MessageType.FILE:
        result = "insert_drive_file";break;
      case MessageType.VOICE:
        result = "keyboard_voice";break;
      case MessageType.LOCATION:
        result = "my_location";break;
    }
    return result;
  }

}
