import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {SessionService} from "../session.service";
import {CompanyPermission} from "@chat/common-model";

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {

  private permission: CompanyPermission;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private sessionService: SessionService
  ) {
  }


  ngOnInit(): void {
  }

  @Input()
  set appHasPermission(val: CompanyPermission) {
    this.permission = val;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;
    if (this.sessionService.user) {
      const permissions = this.sessionService.user.companyPermissions;
      hasPermission = !!permissions && !!permissions.find((cp) => (cp === this.permission));
    }
    return hasPermission;
  }

}
