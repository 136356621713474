import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {WialonService} from "../../../service/wialon.service";
import {Url} from "../../../common/url";
import {SessionService} from "../../../common/auth/session.service";
import {LoginClientType} from "@chat/common-model";

@Component({
  selector: 'app-wialon',
  templateUrl: './wialon.component.html'
})
export class WialonComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private wialonService: WialonService,
              private sessionService: SessionService,
              ) { }

  ngOnInit() {
    this.sessionService.logout().subscribe(() => {
      const queryParamMap = this.route.snapshot.queryParamMap;
      const sid = queryParamMap.get('sid');
      const authHash = queryParamMap.get('authHash');
      const userName = queryParamMap.get('user');

      const observable = authHash ? this.wialonService.loginAuthToken(authHash)
        : (sid ? this.wialonService.loginSid(sid, userName) : null);

      if (observable) {
        observable.subscribe((result) => {
          if (result) {
            this.sessionService.login({
              username: result,
              password: authHash ? authHash : sid,
              client: LoginClientType.WIALON,
            }).subscribe(parsedToken => {
              let route;
              if (this.sessionService.returnRoute) {
                route = this.sessionService.returnRoute;
              } else {
                route = Url.getRouteByRole(parsedToken.role);
              }
              this.router.navigate([route]);
            }, () => {
              this.onError();
            });
          } else {
            this.onError();
          }
        })
      } else {
        this.onError();
      }
    });
  }

  private onError() {
    void this.router.navigate([Url.ROUTE_SIGNIN]);
  }

}
