import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ConversationBean, UserBean, UserRole} from "@chat/common-model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-group-del-participants',
  templateUrl: './group-del-participants.component.html',
  styleUrls: ['./group-del-participants.component.scss']
})
export class GroupDelParticipantsComponent implements OnInit {

  usersLoaded: boolean = false;
  allUsers: UserBean[];
  conversation: ConversationBean;

  @ViewChild('selectedForGroupConversation', { static: false }) selectedForGroupConversation: any;

  constructor(public dialogRef: MatDialogRef<GroupDelParticipantsComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.conversation = data;
  }

  ngOnInit() {
    this.allUsers = this.conversation.participants;
    this.usersLoaded = true;
  }

  onClickCreateConversation(list: any) {
    if (list && list.selectedOptions && list.selectedOptions.selected.length > 0) {
      let participants = list.selectedOptions.selected.map(selected => selected.value);
      this.dialogRef.close(participants);
    }
  }

}
