import {Component, OnInit, ViewChild} from '@angular/core';
import {ConversationBean, ConversationType, UserBean, UserConnectionType, UserRole} from "@chat/common-model";
import {ConversationService} from "../../../service/conversation.service";
import * as moment from 'moment';
import {MatMenu, MatMenuTrigger} from "@angular/material/menu";
import {SessionService} from "../../../common/auth/session.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {GroupDelParticipantsComponent} from "../group/group-del-participants/group-del-participants.component";
import {UserService} from "../../../service/admin/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GroupAddParticipantsComponent} from "../group/group-add-participants/group-add-participants.component";

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent implements OnInit {

  current: ConversationBean;
  ConversationType: any = ConversationType;
  participants: UserBean[];
  user: UserBean;
  directParticipant: UserBean;
  UserConnectionType: any = UserConnectionType;
  spectator: boolean = false;


  @ViewChild(MatMenuTrigger, {static: false}) trigger: MatMenuTrigger;
  @ViewChild('groupMembersMenu', {static: false}) groupMembersMenu: MatMenu;

  constructor(private conversationService: ConversationService,
              private sessionService: SessionService,
              private userService: UserService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.user = this.sessionService.user;
    this.conversationService.currentConversationEmitter.subscribe((current: ConversationBean) => {
      this.current = current;
      if (this.current) {
        this.participants = this.current.participants;
        if (this.current && this.current.type === ConversationType.DIRECT) {
          this.userService.get(this.participants[0].id).subscribe(directParticipant => {
            this.directParticipant = directParticipant;
          })
        }
        this.spectator = this.conversationService.isSpectator(this.current);
      } else {
        this.participants = null;
        this.directParticipant = null;
        this.spectator = false;
      }
    });
  }

  getLastConnection(lastConnection: string, status: UserConnectionType) {
    if (lastConnection && status === UserConnectionType.OFFLINE) {
      const m = moment(lastConnection);
      const today = moment().startOf('day');
      let format = "kk:mm:ss";
      if (today.isAfter(m)) {
        format = "DD MMMM, " + format;
      }
      return " c " + m.format(format);
    }
  }

  onGroupMembersHover(show: boolean) {
     this.trigger.openMenu();
  }

  onClickDelParticipants() {
    const configParticipants = new MatDialogConfig();
    configParticipants.data = this.current;
    const dialogParticipantsRef = this.dialog.open(GroupDelParticipantsComponent, configParticipants);
    dialogParticipantsRef.afterClosed().subscribe((participants: UserBean[]) => {
      if (participants && participants.length > 0) {
        const forUpdate: ConversationBean = JSON.parse(JSON.stringify(this.current));
        forUpdate.participants = forUpdate.participants.filter(p => !participants.find(pp => p.id === pp.id));
        this.userService.updateConversation(this.sessionService.user.id, forUpdate).subscribe((conversation: ConversationBean) => {
          this.snackBar.open("Групповой чат обновлен");
          this.current.participants = this.current.participants.filter(p => !participants.includes(p));
        });
      }

    })
  }

  onClickAddParticipants() {
    const configParticipants = new MatDialogConfig();
    configParticipants.data = this.current;
    const dialogParticipantsRef = this.dialog.open(GroupAddParticipantsComponent, configParticipants);
    dialogParticipantsRef.afterClosed().subscribe((participants: UserBean[]) => {
      if (participants && participants.length > 0) {
        const forUpdate: ConversationBean = JSON.parse(JSON.stringify(this.current));
        forUpdate.participants.push(...participants);
        this.userService.updateConversation(this.sessionService.user.id, forUpdate).subscribe((conversation: ConversationBean) => {
          this.snackBar.open("Групповой чат обновлен");
          this.current.participants.push(...participants);
        });
      }

    })
  }

  isParticipantOperator() {
    return this.user.role === UserRole.OPERATOR && this.current.participants.find(p => {p.id === this.user.id}) !== undefined;
  }
}
