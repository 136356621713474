import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: 'fullDateToDateTime'
})
export class FullDateToDateTimePipe implements PipeTransform {

  transform(value: string, defaultText: string = ''): any {
    return value ? moment(value).format("DD/MM/YY HH:mm") : defaultText;
  }

}
