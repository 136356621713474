import {Component, Input, OnInit} from '@angular/core';
import {ConversationBean, UserBean, getConversationName, UserInfoBean} from "@chat/common-model";
import {ConversationEditParticipantListComponent} from "../participant-list/conversation-edit-participant-list.component";

@Component({
  selector: 'app-conversation-edit-spectator-list',
  templateUrl: '../participant-list/conversation-edit-participant-list.component.html'
})
export class ConversationEditSpectatorListComponent extends ConversationEditParticipantListComponent {

  title: string = 'Список наблюдателей';
  emptyList: string = 'Нет наблюдателей в этом чате'

  onClickDelete(spectator: UserInfoBean) {
    const conversation = JSON.parse(JSON.stringify(this.conversation));
    conversation.spectators = conversation.spectators.filter(s => s.id !== spectator.id);
    this.userService.updateConversation(this.user.id, conversation).subscribe((updated: ConversationBean) => {
      this.conversation.spectators = updated.spectators;
      this.snackBar.open("Наблюдатель удален из чата");
    });
  }
}
