import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSelectionList} from "@angular/material/list";
import {ConversationBean, UserRole} from "@chat/common-model";

@Component({
  selector: 'app-add-role-name',
  templateUrl: './add-role-name.component.html',
  styleUrls: ['./add-role-name.component.scss']
})
export class AddRoleNameComponent implements OnInit {

  otherRoles: UserRole[];
  allRoles: UserRole[] = [UserRole.OPERATOR, UserRole.DRIVER];

  name: string;

  constructor(public dialogRef: MatDialogRef<AddRoleNameComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConversationBean) {
    this.otherRoles = this.allRoles.filter(r => data.roleNames ? !data.roleNames.find(rn => rn === r) : true);
  }

  ngOnInit() {
  }

  onClickAddRoleName(list: MatSelectionList) {
    if (list && list.selectedOptions && list.selectedOptions.selected.length > 0 && this.name) {
      let result = list.selectedOptions.selected.map(selected => { return {
        role: selected.value, conversationName: this.name
      }});
      this.dialogRef.close(result);
    }
  }
}
