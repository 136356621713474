import { Component, OnInit } from '@angular/core';
import {CompanyService} from "../../../../service/admin/company.service";
import {MatDialog, MatDialogConfig, MatSnackBar} from "@angular/material";
import {ConfirmationComponent} from "../../../../common/confirmation/confirmation.component";
import {Router} from "@angular/router";
import {Url} from "../../../../common/url";
import {CompanyBean} from "@chat/common-model";

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {

  companies: CompanyBean[] = [];
  displayedColumns: string[] = ['name', 'info', 'actions'];

  constructor(private companyService: CompanyService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private router: Router) { }

  ngOnInit() {
    this.getCompanies();
  }

  getCompanies() {
    this.companyService.getList().subscribe((list: CompanyBean[]) => {
      this.companies = list;
    })
  }

  onClickEdit(company: CompanyBean) {
    this.router.navigate([Url.ROUTE_ADMIN_COMPANY(company.id)]);
  }

  onClickDelete(company: CompanyBean) {
    const config = new MatDialogConfig();
    config['data'] = {title: 'Удаление', message: 'При удалении компании будут удалены все ее пользователи и их сообщения. Вы действительно хотите удалить компанию?', ok: 'Удалить'};
    const dialogRef = this.dialog.open(ConfirmationComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['data'] === 'ok') {
        this.companyService.remove(company)
          .subscribe(
            deleted => {
              this.snackBar.open('Компания \"' + company.name + '\" удалена');
              this.getCompanies();
            }
          );
      }
    });
  }

  onClickAdd() {
    this.router.navigate([Url.ROUTE_ADMIN_COMPANY_NEW]);
  }
}
