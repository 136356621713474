import {Component, OnInit, Input, ViewChild} from '@angular/core';
import { ConversationBean, getConversationName, UserBean } from '@chat/common-model';
import { conversationDisplayedColumns } from '../../../../../../common/constant/columns';
import {UserService} from "../../../../../../service/admin/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTable} from "@angular/material/table";
import {Url} from "../../../../../../common/url";
import {Router} from "@angular/router";
import {SessionService} from "../../../../../../common/auth/session.service";

@Component({
  selector: 'app-conversation-edit-extra-list',
  templateUrl: './conversation-edit-extra-list.component.html'
})
export class ConversationEditExtraListComponent implements OnInit {

  @Input() conversation: ConversationBean;
  @Input() conversations: ConversationBean[];
  @Input() user: UserBean;

  title: string = 'Список чатов, присоединенных в текущему';

  getConversationName: any = getConversationName;
  conversationDisplayedColumns: any = conversationDisplayedColumns;

  @ViewChild('extraConversationsTable', {static: false}) extraConversationsTable: MatTable<ConversationBean>;

  constructor(private router: Router,
              protected snackBar: MatSnackBar,
              private sessionService: SessionService,
              protected userService: UserService) { }

  ngOnInit() {
  }

  onClickDeleteExtraConversation(conversation: ConversationBean) {
    if (conversation) {
      this.conversation.extraConversations[this.user.id] = this.conversation.extraConversations[this.user.id].filter(ec => ec.id !== conversation.id);
      if (!this.conversations) {
        this.conversations = [];
      }
      this.conversations = this.conversations.filter(c => c.id === conversation.id);
      this.userService.saveConversation(this.user.id, this.conversation).subscribe((conversation: ConversationBean) => {
        this.snackBar.open("Чаты успешно разъеденены");
        this.extraConversationsTable.renderRows();
      });
    }
  }

  onClickOpenConversation(conversation: ConversationBean) {
    this.router.navigate([
      this.sessionService.isSuperAdmin()
        ? Url.ROUTE_ADMIN_USER_CONVERSATION(this.user.id, conversation.id)
        : Url.ROUTE_USER_CONVERSATION(this.user.id, conversation.id)
    ]);
  }

}
