import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {MessageBean} from "@chat/common-model";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-dialog-conversation-view-image',
  templateUrl: './dialog-conversation-view-image.component.html',
  styleUrls: ['./dialog-conversation-view-image.component.scss']
})
export class DialogConversationViewImageComponent implements OnInit {

  image: any;
  message: MessageBean;

  constructor(private sanitizer:DomSanitizer,
              public dialogRef: MatDialogRef<DialogConversationViewImageComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    const reader = new FileReader();
    reader.readAsDataURL(data.image);
    reader.onloadend = () => {
      const base64: string = reader.result as string;
      this.image = base64.split(',')[1];
    };


    //this.image = sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.image));
    this.message = data.message;
  }

  ngOnInit() {
  }

  onClickClose() {
    this.dialogRef.close();
  }

  onClickSave() {
    const link = document.createElement( 'a' );
    link.style.display = 'none';
    document.body.appendChild( link );
    const blob = this.data.image;
    let type = "";
    if (this.data.type) {
      const splitType = this.data.type.split("/");
      if (splitType.length > 1) {
        type = "." + splitType[1];
      }
    }
    link.href = URL.createObjectURL( blob );
    link.download = this.message.id + type;
    link.click();
  }
}
