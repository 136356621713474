import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {AuthService} from "../auth/auth.service";
import {LoginClientType} from "@chat/common-model";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var urlRequest = request.url;
    if (urlRequest.indexOf('public/') > -1) {
      return next.handle(request);
    }

    const token = this.authService.getToken();
    if (token) {
      request = request.clone({setHeaders: {Authorization: 'bearer ' + this.authService.getToken()}});
    }
    request = request.clone({setHeaders: {'x-client': LoginClientType.WEB}});
    return next.handle(request);
  }
}
