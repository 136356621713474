import { Pipe, PipeTransform } from '@angular/core';
import {MessageStatusType} from "@chat/common-model";

@Pipe({
  name: 'msgStatusToIcon'
})
export class MsgStatusToIconPipe implements PipeTransform {

  transform(value: MessageStatusType, ...args: any[]): any {
    let result;
    switch (value) {
      case MessageStatusType.DRAFT:
        result = "";break;
      case MessageStatusType.SENT:
        result = "done";break;
      case MessageStatusType.DELIVERED:
      case MessageStatusType.SEEN:
        result = "done_all";break;
    }
    return result;
  }

}
