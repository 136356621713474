import { Injectable } from '@angular/core';
import {HttpService} from "../../common/http/http.service";
import {Observable} from "rxjs";
import {Url} from "../../common/url";
import {CompanyBean, ResponseStatusBean, UserBean} from "@chat/common-model";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private httpService: HttpService) { }

  public getList(): Observable<{} | CompanyBean[]> {
    return this.httpService.get<CompanyBean[]>({url: Url.API_ADMIN_COMPANIES});
  }

  public get(id: string): Observable<{} | CompanyBean> {
    if (!id) {
      return new Observable((observer) => {
        observer.next({});
        observer.complete();
      });
    } else {
      return this.httpService.get<CompanyBean>({url: Url.API_ADMIN_COMPANY(id)});
    }
  }

  public save(company: CompanyBean): Observable<{} | CompanyBean> {
    return company.id ? this.update(company) : this.create(company);
  }

  public create(company: CompanyBean): Observable<{} | CompanyBean> {
    return this.httpService.post<CompanyBean>({url: Url.API_ADMIN_COMPANIES, body: company});
  }

  public update(company: CompanyBean): Observable<{} | CompanyBean> {
    return this.httpService.put<CompanyBean>({url: Url.API_ADMIN_COMPANY(company.id), body: company});
  }

  public remove(company: CompanyBean): Observable<{} | ResponseStatusBean> {
    return this.httpService.del<ResponseStatusBean>({url: Url.API_ADMIN_COMPANY(company.id)});
  }

  public getUserList(companyId: string): Observable<{} | UserBean[]> {
    return this.httpService.get<UserBean[]>({url: Url.API_ADMIN_COMPANY_USERS(companyId)});
  }
}
