import {Injectable} from '@angular/core';
import {SessionService} from '../session.service';
import {CanActivate, Router, ActivatedRouteSnapshot, ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {Url} from "../../url";

@Injectable()
export class AuthUserWithRolesGuard implements CanActivate {

  constructor(private sessionService: SessionService, private router: Router, private route: ActivatedRoute) {
  }

  public canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    console.log('[AuthUWRGuard]');
    const expectedRoles: string[] = route.data['expectedRole'];

    if (this.sessionService.isAuthenticated) {
      console.log('AuthUWRGuard: Authenticated');
      if (!expectedRoles || expectedRoles.indexOf(this.sessionService.user.role) > -1) {
        console.log('AuthRolesGuard: role checked');
        return true;
      } else {
        const {role} = this.sessionService.user;
        const routeToRedirect = Url.getRouteByRole(role);
        console.log(routeToRedirect);
        this.router.navigate([routeToRedirect]);
        return false;
      }
    } /*else if (this.sessionService.isEnabled) {
      console.log('AuthUserGuard: navigate to signin');
      this.router.navigate([Url.ROUTE_SIGNIN]);
      return false;
    }*/ else {
      console.log('AuthUWRGuard: loading...');
      return new Observable((observer) => {
        this.sessionService.loadSession().subscribe(result => {
          if (result === 0) {
            this.sessionService.processUser().subscribe(res => {
              if (res === 0) {
                if (!expectedRoles || expectedRoles.indexOf(this.sessionService.user.role) > -1) {
                  console.log('AuthRolesGuard: role checked');
                  observer.next(true);
                } else {
                  console.log('AuthRolesGuard: redirect to forbidden');
                  this.router.navigate([Url.ROUTE_FORBIDDEN]);
                  observer.next(false);
                }
                observer.complete()
              } else {
                console.log("AuthUWRGuard: Cant process user");
                this.gotoSignin(observer);
              }
            });
          } else {
            this.gotoSignin(observer);
          }
          console.log('AuthUWRGuard: result: ' + result);
        });
      });
    }
  }

  private gotoSignin(observer) {
    this.sessionService.processAnonymous();
    const route = document.location.pathname;
    if (route !== Url.ROUTE_ROOT && route !== Url.ROUTE_ROOT + '/') {
      console.log("set return route: " + route);
      this.sessionService.returnRoute = route;
    }
    this.router.navigate([Url.ROUTE_SIGNIN]);
    observer.next(false);
    observer.complete();
  }

}
