import {Component, Input, OnInit} from '@angular/core';
import {ParticipantBean, getConversationName, ConversationBean, UserBean, UserInfoBean} from "@chat/common-model";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {EditMemberNameComponent} from "../../edit-member-name/edit-member-name.component";
import {UserService} from "../../../../../../service/admin/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import { participantDisplayedColumns } from '../../../../../../common/constant/columns';

@Component({
  selector: 'app-conversation-edit-participant-list',
  templateUrl: './conversation-edit-participant-list.component.html'
})
export class ConversationEditParticipantListComponent implements OnInit {

  @Input() conversation: ConversationBean;
  @Input() userList: ParticipantBean[];
  @Input() user: UserBean;

  title: string = 'Список участников';
  emptyList: string = 'Нет участников в этом чате'

  getConversationName: any = getConversationName;
  participantDisplayedColumns: string[] = participantDisplayedColumns;

  constructor(protected dialog: MatDialog,
              protected snackBar: MatSnackBar,
              protected userService: UserService) { }

  ngOnInit() {
  }

  onClickParticipantName(participant: ParticipantBean) {
    const config = new MatDialogConfig();
    config.data = participant;
    const dialogRef = this.dialog.open(EditMemberNameComponent, config);
    dialogRef.afterClosed().subscribe((name: string) => {
      if (name || name === null) {
        const conversation: ConversationBean = JSON.parse(JSON.stringify(this.conversation));
        if (conversation.participants) {
          const pMember = conversation.participants.find(p => p.id === participant.id);
          if (pMember) {
            pMember.conversationName = name;
          }
        }
        if (conversation.spectators) {
          const sMember = conversation.spectators.find(s => s.id === participant.id);
          if (sMember) {
            sMember.conversationName = name;
          }
        }
        this.userService.updateConversation(this.user.id, conversation).subscribe((updated: ConversationBean) => {
          if (this.conversation.participants) {
            const pm = this.conversation.participants.find(p => p.id === participant.id);
            if (pm) {
              pm.conversationName = name;
            }
          }
          if (this.conversation.spectators) {
            const sm = this.conversation.spectators.find(s => s.id === participant.id);
            if (sm) {
              sm.conversationName = name;
            }
          }
          this.snackBar.open(`Чат обновлен`);
        });
      }
    })
  }

  onClickDelete(participant: UserInfoBean) {
    const conversation = JSON.parse(JSON.stringify(this.conversation));
    conversation.participants = conversation.participants.filter(p => p.id !== participant.id);
    this.userService.updateConversation(this.user.id, conversation).subscribe((updated: ConversationBean) => {
      this.conversation.participants = updated.participants;
      this.snackBar.open("Участник удален из чата");
    });
  }

  onClickEditParticipant(p: UserInfoBean) {
    //todo: need to implement
  }

}
