import {EventEmitter, Injectable} from '@angular/core';
import {HttpService} from "../common/http/http.service";
import {MessageBean, MessageListBean, MessageStatusList, StatusType, Subject} from "@chat/common-model";
import {Url} from "../common/url";
import {MessageService} from "./message.service";

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  public newMessagesEmitter: EventEmitter<MessageListBean>;
  public statusUpdatedMessagesEmitter: EventEmitter<MessageListBean>;
  public seenMessagesEmitter: EventEmitter<MessageListBean>;
  public statusUpdatedParticipantsEmitter: EventEmitter<Subject[]>;
  private emitters: any;

  private interval: any;
  private frequency: number = 5000;
  private statusInterval: any;
  private statusFrequency: number = 60000;

  constructor(private httpService: HttpService,
              private messageService: MessageService) {
    this.newMessagesEmitter = new EventEmitter<MessageListBean>();
    this.statusUpdatedMessagesEmitter = new EventEmitter<MessageListBean>();
    this.statusUpdatedParticipantsEmitter = new EventEmitter<Subject[]>();
    this.seenMessagesEmitter = new EventEmitter<MessageListBean>();

    this.emitters = {
      [StatusType.MESSAGES_NEW]: this.newMessagesEmitter,
      [StatusType.SPECTATOR_MESSAGES_NEW]: this.newMessagesEmitter,
      [StatusType.MESSAGES_STATUS_UPDATED]: this.statusUpdatedMessagesEmitter,
      [StatusType.PARTICIPANT_STATUS_UPDATED]: this.statusUpdatedParticipantsEmitter
    }
  }

  public start(): void {
    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.httpService.get({url: Url.API_STATUSES}).subscribe((statuses: MessageStatusList) => {
        if (statuses && statuses.list && statuses.list.length > 0) {
          this.httpService.del({url: Url.API_STATUSES}).subscribe();
          statuses.list.forEach(status => {
            if (status && status.subjects && status.subjects.length > 0) {
            ///  if (status.type !== StatusType.PARTICIPANT_STATUS_UPDATED) {
                status.subjects.forEach(subject => {
                  this.messageService.syncMessageList(subject.id, subject.serverTime, status.type).subscribe((list: MessageListBean) => {
                    if (!list.conversation) {
                      list.conversation = {id: subject.id};
                    }
                    this.emitters[status.type].emit(list);
                  })
                });
            //  } else {
            //    this.statusUpdatedParticipantsEmitter.emit(status.subjects);
            //  }
            }
          });
        }
      });
    }, this.frequency);
/*    this.statusInterval = setInterval(() => {
      this.getUserStatuses();
    }, this.statusFrequency);*/
    console.log("Statuses poll started");
  }

  public stop(): void {
    clearInterval(this.interval);
    this.interval = null;
    clearInterval(this.statusInterval);
    this.statusInterval = null;
    console.log("Statuses poll stopped");
  }

  public getUserStatuses() {
    this.httpService.get({url: Url.API_USERS_STATUSES}).subscribe((subjects: Subject[]) => {
      this.statusUpdatedParticipantsEmitter.emit(subjects);
    });
  }

}
