import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Url} from "../../../../common/url";
import {UserService} from "../../../../service/admin/user.service";
import {CompanyService} from "../../../../service/admin/company.service";
import {MatSnackBar} from "@angular/material";
import {SessionService} from "../../../../common/auth/session.service";
import {
  ConversationBean,
  ConversationRoleNameBean,
  ConversationType,
  getConversationName,
  UserBean,
  ConversationRole,
  getConversationRole
} from "@chat/common-model";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {SpectatorsComponent} from "./spectators/spectators.component";
import {UserRolePipe} from "../../../../common/pipe/user-role.pipe";
import {AddRoleNameComponent} from "./add-role-name/add-role-name.component";
import {ParticipantsComponent} from "./participants/participants.component";
import {
  SelectConversationComponent,
  SelectConversationDataType
} from "../../../../common/dialog/select-conversation/select-conversation.component";

@Component({
  selector: 'app-conversation-edit',
  templateUrl: './conversation-edit.component.html',
  styleUrls: ['./conversation-edit.component.scss']
})
export class ConversationEditComponent implements OnInit {

  title: string;
  conversation: ConversationBean;
  conversationRole: ConversationRole = ConversationRole.UNKNOWN;
  types: ConversationType[] = [ConversationType.DIRECT, ConversationType.GROUP];
  participantDisplayedColumns: string[] = ['name', 'role', 'conversationName', 'actions'];
  conversationDisplayedColumns: string[] = ['name', 'participants', 'type', 'actions'];
  ConversationType: any = ConversationType;
  users: UserBean[] = [];
  user: UserBean;

  userId: string;
  companyId: string;

  loaded: boolean = false;

  getConversationName: any = getConversationName;

  extraConversations: ConversationBean[];
  extraConversationIn: ConversationBean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private userRolePipe: UserRolePipe,
              private sessionService: SessionService,
              private userService: UserService,
              private companyService: CompanyService) {
  }

  ngOnInit() {
    this.loaded = false;
    this.route.params.subscribe(params => {
      const {userId, conversationId} = params;
      this.userId = userId;
      this.title = conversationId ? "Редактирование чата" : "Новый чат";

      this.userService.get(userId).subscribe((user: UserBean) => {
        this.user = user;
        this.companyId = user.companyId;
        const userList = this.sessionService.isSuperAdmin() ? this.companyService.getUserList(user.companyId) : this.userService.getList();
        userList.subscribe((users: UserBean[]) => {
          this.users = users;
          if (conversationId) {
            this.userService.getConversation(userId, conversationId).subscribe((conversation: ConversationBean) => {
              this.conversation = conversation;
              this.conversationRole = getConversationRole(this.user, this.conversation);
              this._getExtraConversations();
              this.loaded = true;
            });
          } else {
            this.conversation = {
              type: ConversationType.DIRECT,
              participants: [{id: user.id, fullName: user.fullName, role: user.role, contactName: user.contactName}]
            };
            this.conversationRole = ConversationRole.PARTICIPANT;
            this._getExtraConversations();
            this.loaded = true;
          }
        });
      });


    })
  }

  _getExtraConversations() {
    this.extraConversations = null;
    this.extraConversationIn = null;
    if (ConversationRole.PARTICIPANT === this.conversationRole) {
      this.userService.getConversationList(this.userId).subscribe((conversations: ConversationBean[]) => {
        if (conversations) {
          this.extraConversations = this._filterExtraSpectatorConversations(conversations);
        }
      });
    } else if (ConversationRole.SPECTATOR === this.conversationRole) {
      this.userService.getConversationList(this.userId).subscribe((conversations: ConversationBean[]) => {
        if (conversations) {
          const filtered = this._filterExtraParticipantConversations(conversations);
          if (filtered) {
            this.extraConversationIn = filtered.find(c => c.extraConversations
              && c.extraConversations[this.userId]
              && c.extraConversations[this.userId].find(ec => ec.id === this.conversation.id));
          }
          if (!this.extraConversationIn) {
            this.extraConversations = filtered;
          }
        }
      });
    }
  }

  _filterExtraSpectatorConversations(conversations: ConversationBean[]): ConversationBean[] {
    return conversations.filter(c => c.spectators && c.spectators.find(sp => sp.id === this.userId)
      && c.type === ConversationType.DIRECT
      && (!this.conversation.extraConversations
        || !this.conversation.extraConversations[this.userId]
        || !this.conversation.extraConversations[this.userId].find(ec => ec.id === c.id)));
  }

  _filterExtraParticipantConversations(conversations: ConversationBean[]): ConversationBean[] {
    return  conversations.filter(c => c.participants && c.participants.find(sp => sp.id === this.userId)
      && c.type === ConversationType.DIRECT);
  }

  onClickClose() {
    this.router.navigate([Url.ROUTE_ADMIN_USER(this.userId)]);
  }

  onClickSave() {
    this.userService.saveConversation(this.user.id, this.conversation).subscribe((conversation: ConversationBean) => {
      this.snackBar.open("Чат успешно сохранен");
    });
  }

  onClickAddParticipant() {
    const config = new MatDialogConfig();
    config.data = {
      conversation: this.conversation,
      companyId: this.companyId,
    };
    const dialogSpectatorsRef = this.dialog.open(ParticipantsComponent, config);
    dialogSpectatorsRef.afterClosed().subscribe((participants: UserBean[]) => {
      if (participants && participants.length > 0) {
        const conversation = JSON.parse(JSON.stringify(this.conversation));
        for (const participant of participants) {
          if (!conversation.participants.find(p => p.id === participant.id)) {
            conversation.participants.push(participant);
          }
        }
        this.userService.updateConversation(this.userId, conversation).subscribe((updated: ConversationBean) => {
          this.conversation.participants = updated.participants;
          this.snackBar.open("Участники добавлены");
        });
      }
    })
  }

  onClickAddSpectator() {
    const configSpectators = new MatDialogConfig();
    configSpectators.data = {
      conversation: this.conversation,
      companyId: this.companyId,
    };
    const dialogSpectatorsRef = this.dialog.open(SpectatorsComponent, configSpectators);
    dialogSpectatorsRef.afterClosed().subscribe((spectators: UserBean[]) => {
      if (spectators && spectators.length > 0) {
        this.conversation.spectators = spectators;
        this.userService.updateConversation(this.userId, this.conversation).subscribe((updated: ConversationBean) => {
          this.snackBar.open("Чат обновлен");
        });
      }
    })
  }


  onClickAddRoleName() {
    const config = new MatDialogConfig();
    config.data = this.conversation;
    const dialogRef = this.dialog.open(AddRoleNameComponent, config);
    dialogRef.afterClosed().subscribe((roleNames: ConversationRoleNameBean[]) => {
      if (roleNames && roleNames.length > 0) {
        const conversation: ConversationBean = JSON.parse(JSON.stringify(this.conversation));
        conversation.roleNames = conversation.roleNames.concat(roleNames);
        this.userService.updateConversation(this.userId, conversation).subscribe((updated: ConversationBean) => {
          this.conversation.roleNames = updated.roleNames;
          this.snackBar.open(`Чат обновлен`);
        });
      }
    })
  }

  onClickDeleteRoleName(roleName: ConversationRoleNameBean) {
    const conversation: ConversationBean = JSON.parse(JSON.stringify(this.conversation));
    conversation.roleNames = conversation.roleNames.filter(rn => rn.role !== roleName.role);
    this.userService.updateConversation(this.userId, conversation).subscribe((updated: ConversationBean) => {
      this.conversation.roleNames = updated.roleNames;
      this.snackBar.open(`Название для роли ${this.userRolePipe.transform(roleName.role)} удалено`);
    });
  }

  onClickAddExtra() {
    if (this.extraConversations && this.extraConversations.length > 0) {
      const config = new MatDialogConfig<SelectConversationDataType>();
      config.data = {
        title: 'Выберите чат для объединения',
        user: this.user,
        conversations: this.extraConversations,
      };
      const dialogRef = this.dialog.open(SelectConversationComponent, config);
      dialogRef.afterClosed().subscribe((conversation: ConversationBean) => {
        if (conversation) {
          if (ConversationRole.PARTICIPANT === this.conversationRole) {
            this._addExtraConversation(this.conversation, conversation, false);
          } else if (ConversationRole.SPECTATOR === this.conversationRole) {
            this._addExtraConversation(conversation, this.conversation, true);
          }
        }
      });
    }
  }

  _addExtraConversation(conversation: ConversationBean, extraConversation: ConversationBean, clear: boolean) {
    if (!conversation.extraConversations) {
      conversation.extraConversations = {};
    }
    if (!conversation.extraConversations[this.userId]) {
      conversation.extraConversations[this.userId] = [];
    }
    conversation.extraConversations[this.userId].push(extraConversation);
    if (clear) {
      this.extraConversations = [];
    } else {
      this.extraConversations = this.extraConversations.filter(ec => ec.id !== extraConversation.id);
    }

    this.userService.saveConversation(this.user.id, conversation).subscribe(() => {
      this.snackBar.open("Чаты успешно объеденены");
    });
  }

  onClickDeleteExtraConversation(conversation: ConversationBean) {
    if (conversation) {
      this.conversation.extraConversations[this.userId] = this.conversation.extraConversations[this.userId].filter(ec => ec.id !== conversation.id);
      if (!this.extraConversations) {
        this.extraConversations = [];
      }
      this.extraConversations.push(conversation);
      this.userService.saveConversation(this.user.id, this.conversation).subscribe((conversation: ConversationBean) => {
        this.snackBar.open("Чаты успешно разъеденены");
      });
    }
  }

}
