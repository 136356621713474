import {EventEmitter, Injectable} from '@angular/core';
import {HttpService} from "../common/http/http.service";
import {Observable} from "rxjs";
import {Url} from "../common/url";
import {tap} from "rxjs/operators";
import {Howl} from 'howler';
import {
  DirectionType,
  FilterList,
  MessageBean,
  MessageDirectionType,
  MessageListBean,
  MessageType,
  ResponseStatusBean,
  StatusType
} from "@chat/common-model";
import {FileMessage} from "../page/chat/dialog-post/dialog-file-dialog/dialog-file-dialog.component";
import {HttpHeaders} from "@angular/common/http";
import {EsmRenderingFormatter} from "@angular/compiler-cli/ngcc/src/rendering/esm_rendering_formatter";

export enum MessageFileType {
  ORIGIN,
  THUMBNAIL,
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public postMessageEmitter: EventEmitter<MessageBean>;
  public imageMessageLoadedEmitter: EventEmitter<MessageBean>;
  public typeMessageEmitter: EventEmitter<MessageDirectionType>;

  private audio: HTMLAudioElement;

  public filters: FilterList = new FilterList();
  public tableFilters: FilterList = new FilterList();

  public windowFocus: boolean = true;

  constructor(private httpService: HttpService) {
    this.postMessageEmitter = new EventEmitter<MessageBean>();
    this.imageMessageLoadedEmitter = new EventEmitter<MessageBean>();
    this.typeMessageEmitter = new EventEmitter<MessageDirectionType>();


    this.audio = new Howl({
      src: ['/assets/sound/new-message.mp3', '/assets/sound/new-message.ogg'],
      autoplay: true,
    });


    window.addEventListener('focus', event => {
      this.windowFocus = true;
    });
    window.addEventListener('blur', event => {
      this.windowFocus = false;
    });

  }

  public getMessage(conversationId: string, messageId: string): Observable<MessageBean> {
    return this.httpService.get<MessageBean>({url: Url.API_MESSAGE(conversationId, messageId)});
  }

  public getAllMessageList(companyId?: string, from?: string, count?: number, filters?: FilterList): Observable<{} | MessageListBean> {
    const search = {};
    if (companyId) search["company"] = companyId;
    if (from) search["from"] = from;
    if (count) search["count"] = count;
    if (filters) search["filters"] = filters.getQueryString();
    return this.httpService.get<MessageListBean>({search: search, url: Url.API_ALL_MESSAGES});
  }

  public getUserMessageList(from?: string, count?: number, filters?: FilterList): Observable<{} | MessageListBean> {
    const search = {};
    if (from) search["from"] = from;
    if (count) search["count"] = count;
    if (filters) search["filters"] = filters.getQueryString();
    return this.httpService.get<MessageListBean>({search: search, url: Url.API_USER_MESSAGES});
  }

  public getMessageList(conversationId: string, direction: DirectionType, from?: string, count?: number): Observable<{} | MessageListBean> {
    const search = {direction};
    if (from) search["from"] = from;
    if (count) search["count"] = count;
    return this.httpService.get<MessageListBean>({search: search, url: Url.API_MESSAGES(conversationId)});
  }

  public getUnSeenMessageList(conversationId: string, from?: string): Observable<{} | MessageListBean> {
    const search = {};
    if (from) search["from"] = from;
    return this.httpService.get<MessageListBean>({search: search, url: Url.API_MESSAGES_UNSEEN(conversationId)});
  }

  public postMessage(conversationId: string, message: MessageBean): Observable<{} | MessageBean> {
    return this.httpService.post<MessageBean>({url: Url.API_MESSAGES(conversationId), body: message})
      .pipe(tap((message: MessageBean) => {
        this.postMessageEmitter.emit(message);
      }));
  }

  public forwardMessage(conversationId: string, message: MessageBean): Observable<{} | MessageBean> {
    return this.httpService.put<MessageBean>({url: Url.API_FORWARD_MESSAGE(conversationId), body: message})
      .pipe(tap((message: MessageBean) => {
        this.postMessageEmitter.emit(message);
      }));
  }

  public postFileMessage(conversationId: string, fm: FileMessage): Observable<{} | MessageBean> {
    const fd = new FormData();
    fd.append('message', JSON.stringify(fm.message));
    fd.append('file', fm.file);

    const headers = new HttpHeaders();

    return this.httpService.postMultipart({
      url: Url.API_MESSAGES_FILE(conversationId),
      body: fd,
      headers: headers
    }).pipe(tap((message: MessageBean) => {
      this.postMessageEmitter.emit(message);
    }));

  }

  public getFileMimeType(message: MessageBean, type?: MessageFileType): string {
    switch (type) {
      case MessageFileType.ORIGIN:
        return message.mimeType;
      case MessageFileType.THUMBNAIL:
        return 'image/jpeg';
      default:
        return message.type === MessageType.IMAGE ? 'image/jpeg' : message.mimeType;
    }
  }

  public getFile(message: MessageBean, type?: MessageFileType): Observable<{} | any> {
    let url;
    switch (type) {
      case MessageFileType.ORIGIN:
        url = message.url;
        break;
      case MessageFileType.THUMBNAIL:
        url = message.thumbnail + '-jpeg';
        break;
      default:
        url = message.url + (message.type === MessageType.IMAGE ? '/jpeg' : '');
    }
    return this.httpService.get<any>({url: url, responseType: 'arraybuffer'});
  }

  public syncMessageList(conversationId: string, from: string, type: StatusType): Observable<{} | MessageListBean> {
    const search = {from, type, fill: "true"};
    return this.httpService.get<MessageListBean>({search: search, url: Url.API_MESSAGES_SYNC(conversationId)});
  }

  public setSeen(conversationId: string, messages: MessageBean[]): Observable<{} | ResponseStatusBean> {
    const body = messages.map(m => {
      return {id: m.id}
    });
    return this.httpService.put({url: Url.API_MESSAGES_BATCH_SEEN(conversationId), body: body});
  }

  public play() {
    this.audio.play();
  }

}
