import {Injectable} from '@angular/core';
import {SessionService} from '../session.service';
import {CanActivate, Router, ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Url} from "../../url";

@Injectable()
export class AuthUserGuard implements CanActivate {

  constructor(private sessionService: SessionService, private router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    console.log('[AuthUserGuard]');

    if (this.sessionService.isAuthenticated) {
      console.log('AuthUserGuard: Authenticated');
      return true;
    } /*else if (this.sessionService.isEnabled) {
      console.log('AuthUserGuard: navigate to signin');
      this.router.navigate([Url.ROUTE_SIGNIN]);
      return false;
    }*/ else {
      console.log('AuthUserGuard: loading...');
      return new Observable((observer) => {
        this.sessionService.loadSession().subscribe(result => {
          if (result === 0) {
            this.sessionService.processUser().subscribe(res => {
              if (res === 0) {
                observer.next(true);
                observer.complete();
              } else {
                console.log("Cant process user");
                this.gotoSignin(observer);
              }
            });
          } else {
            this.gotoSignin(observer);
          }
          console.log('AuthUserGuard: result: ' + result);
        });
      });
    }
  }

  private gotoSignin(observer) {
    this.sessionService.processAnonymous();
    this.router.navigate([Url.ROUTE_SIGNIN]);
    observer.next(false);
    observer.complete();
  }

}
