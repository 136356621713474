import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CompanyService} from "../../../../service/admin/company.service";
import {Url} from "../../../../common/url";
import {MatDialog, MatDialogConfig, MatSnackBar} from "@angular/material";
import {UserService} from "../../../../service/admin/user.service";
import {ConfirmationComponent} from "../../../../common/confirmation/confirmation.component";
import {CompanyBean, UserBean, UserConnectionType, CompanyPermission} from "@chat/common-model";
import * as moment from "moment";
import {InfoService} from "../../../../service/info.service";
import {WialonService} from "../../../../service/wialon.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit, OnDestroy {

  title: string;
  company: CompanyBean;
  users: UserBean[] = [];
  userDisplayedColumns: string[] = ['role-icon', 'name', 'contact-name', 'status', 'lastConnected', 'lastPushConnected', 'version', 'actions'];
  filteredUsers: UserBean[];
  UserConnectionType: any = UserConnectionType;
  CompanyPermission: any = CompanyPermission;

  allPermissions: {
    permission: CompanyPermission,
    checked: boolean,
  }[] = Object.keys(CompanyPermission).map((cp) => ({
    permission: cp as CompanyPermission,
    checked: false,
  }));

  lastVersion: string;
  lastMinor: string;

  search: string;

  loaded: boolean = false;

  wialonLoginSubscription: Subscription;
  wialonLoggedIn: boolean = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private companyService: CompanyService,
              private userService: UserService,
              private infoService: InfoService,
              private wialonService: WialonService) {
  }

  ngOnInit() {
    this.loaded = false;
    this.route.params.subscribe(params => {
      const {id} = params;
      this.title = id ? "Редактирование компании" : "Новая компания"

      this.infoService.getLastVersion().subscribe((lastVersion) => {
        this.lastVersion = lastVersion.version;
        const parts = this.lastVersion.split('.');
        this.lastMinor = parts.slice(0, 1).join('.');
      })

      this.companyService.get(id).subscribe((company: CompanyBean) => {
        this.company = company;

        if (company.access) {
          company.access.forEach((access) => {
            const permission = this.allPermissions.find((p) => access === p.permission);
            if (permission) {
              permission.checked = true;
            }
          });
        }

        this.wialonLoginSubscription = this.wialonService.wialonLoginEmitter.subscribe((token) => {
          this.wialonLoggedIn = !!token;
        });

        this.getUsers();
      }, (error) => {}, () => {this.loaded = true});
    })
  }

  ngOnDestroy(): void {
    if (this.wialonLoginSubscription) this.wialonLoginSubscription.unsubscribe();
  }

  getUsers() {
    if (this.company.id) {
      this.companyService.getUserList(this.company.id).subscribe((users: UserBean[]) => {
        this.users = users;
        this.filteredUsers = this.users;
      })
    }
  }

  onClickClose() {
    this.router.navigate([Url.ROUTE_ADMIN_COMPANIES]);
  }

  onClickSave() {
    this.company.access = this.allPermissions
      .filter((perm) => perm.checked)
      .map((perm) => perm.permission);
    this.companyService.save(this.company).subscribe((company: CompanyBean) => {
      this.snackBar.open("Компания успешно сохранена");
      this.router.navigate([Url.ROUTE_ADMIN_COMPANY(company.id)]);
    })
  }

  onClickDeleteUser(user: UserBean) {
    const config = new MatDialogConfig();
    config['data'] = {title: 'Удаление', message: 'При удалении пользователя будут удалены их сообщения. Вы действительно хотите удалить пользователя?', ok: 'Удалить'};
    const dialogRef = this.dialog.open(ConfirmationComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['data'] === 'ok') {
        this.userService.remove(user).subscribe(
            deleted => {
              this.snackBar.open('Пользователь \"' + user.fullName + '\" удален');
              this.getUsers();
            }
          );
      }
    });
  }

  onClickEditUser(user: UserBean) {
    this.router.navigate([Url.ROUTE_ADMIN_USER(user.id)])
  }

  onClickAddUser() {
    this.router.navigate([Url.ROUTE_ADMIN_COMPANY_USER_NEW(this.company.id)]);
  }


  onClickClearSearch() {
    this.search = null;
    this.onChangeSearch();
  }

  onChangeSearch() {
    if (this.search) {
      this.filteredUsers = this.users.filter(option => new RegExp(this.search, 'gi')
        .test(option.fullName + " " + option.contactName));
    } else {
      this.filteredUsers = this.users;
    }
  }

  getLastConnection(lastConnection: string, status: UserConnectionType) {
    if (lastConnection && status === UserConnectionType.OFFLINE) {
      const m = moment(lastConnection);
      const now = moment.utc();
      if (now.diff(m, "years") < 90) {
        const today = moment().startOf('day');
        let format = "kk:mm:ss";
        if (today.isAfter(m)) {
          format = "DD.MM, " + format;
        }
        return m.format(format);
      } else {
        return "";
      }
    }
  }

  getLastPushConnection(lastConnection: string) {
    if (lastConnection) {
      const m = moment(lastConnection);
      const now = moment.utc();
      if (now.diff(m, "years") < 90) {
        const today = moment().startOf('day');
        let format = "kk:mm:ss";
        if (today.isAfter(m)) {
          format = "DD.MM, " + format;
        }
        return m.format(format);
      } else {
        return "";
      }
    }
  }

  getVersionClass(user: UserBean): string {
    if (!user.xMobileVersion) return 'old';

    const parts = user.xMobileVersion.split('.');
    const minor = parts.slice(0, 1).join('.');

    return user.xMobileVersion === this.lastVersion ? 'current' : (minor === this.lastMinor ? 'normal' : 'old');
  }

  onClickOpenHistory() {
    this.router.navigate([Url.ROUTE_HISTORY], {
        queryParams: {companyId: this.company.id}
      }
    );
  }

  onClickWialonLogin() {
    this.wialonService.login();
  }

  onClickWialonSyncUsers() {
    this.wialonService.syncUsers(this.company).subscribe((result) => {
      this.snackBar.open(`Пользователи синхронизорованы: ${result} создано`);
      this.getUsers();
    })
  }
}
