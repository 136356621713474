import {EventEmitter, Injectable} from '@angular/core';
import {HttpService} from "../common/http/http.service";
import {Observable} from "rxjs";
import {Url} from "../common/url";
import {ConversationBean} from "@chat/common-model";
import {SessionService} from "../common/auth/session.service";

@Injectable({
  providedIn: 'root'
})
export class ConversationService {

  private currentConversation: ConversationBean = {};
  public currentConversationEmitter: EventEmitter<ConversationBean>;
  public sortConversationEmitter: EventEmitter<boolean>;
  public messageTexts: any = {};

  public alphabet: boolean = false;
  public showAlphabet: boolean = false;

  constructor(private httpService: HttpService,
              private sessionService: SessionService) {
    this.currentConversationEmitter = new EventEmitter<ConversationBean>();
    this.sortConversationEmitter = new EventEmitter<boolean>();
    this.sortConversationEmitter.subscribe((alphabet) => {
      this.alphabet = alphabet;
    });
  }


  public getConversationList(): Observable<{} | ConversationBean[]> {
    return this.httpService.get<ConversationBean[]>({url: Url.API_CONVERSATIONS});
  }

  public get(conversationId: string): Observable<{} | ConversationBean> {
    return this.httpService.get<ConversationBean>({url: Url.API_CONVERSATION(conversationId)});
  }

  public selectCurrentConversation(conversation: ConversationBean) {
    this.currentConversation = conversation;
    this.currentConversationEmitter.emit(conversation);
  }

  public isSpectator(conversation: ConversationBean): boolean {
    const spectator = conversation && conversation.spectators
      && conversation.spectators.find(sp => sp.id === this.sessionService.user.id) !== undefined;
    console.log("Spectator: " + spectator);
    return spectator;
  }
}
