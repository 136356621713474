import { Pipe, PipeTransform } from '@angular/core';
import {ConversationType} from "@chat/common-model";

@Pipe({
  name: 'conversationType'
})
export class ConversationTypePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let text;
    switch (value) {
      case ConversationType.DIRECT:
        text = "Личный";break;
      case ConversationType.GROUP:
        text = "Групповой";break;
    }
    return text;
  }

}
