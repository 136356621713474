import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameToColor'
})
export class NameToColorPipe implements PipeTransform {

  colors: string[] = ["#ed5565", "#fc6e51", "#ffce54", "#a0d468", "#48cfad", "#4fc1e9", "#5d9cec", "#ac92ec", "#ec87c0", "#ccd1d9"];

  transform(value: string, ...args: any[]): any {
    let i = 0;
    if (value) {
      i = value.length % 10;
    }
    return this.colors[i];
  }

}
