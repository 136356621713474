import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {MessageService} from "../../../service/message.service";
import {ConversationService} from "../../../service/conversation.service";
import {ConversationBean, MessageBean, UserRole} from "@chat/common-model";
import {MatDialog} from "@angular/material";
import {DialogFileDialogComponent, FileMessage} from "./dialog-file-dialog/dialog-file-dialog.component";
import {SessionService} from "../../../common/auth/session.service";
import {Observable, Subscription} from "rxjs";
import {$e} from "codelyzer/angular/styles/chars";
import {concatMap} from "rxjs/operators";

@Component({
  selector: 'app-dialog-post',
  templateUrl: './dialog-post.component.html',
  styleUrls: ['./dialog-post.component.scss']
})
export class DialogPostComponent implements OnInit, OnDestroy {

  @Input() conversations: ConversationBean[];
  @Input() replyTo: MessageBean;
  //conversation: ConversationBean;
  spectator: boolean = false;
  messageText: string;
  @Output('sent') sent = new EventEmitter();

  @ViewChild('file', {static: false}) file;

  currentConversationSubscription: Subscription;

  fileLoading: boolean = false;

  constructor(private element: ElementRef, private renderer: Renderer2,
              private conversationService: ConversationService,
              private messageService: MessageService,
              private sessionService: SessionService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    if (!this.conversations) {
      this.currentConversationSubscription = this.conversationService.currentConversationEmitter.subscribe((current: ConversationBean) => {
        if (this.conversations && current && this.conversations[0].id !== current.id) {
          this.conversationService.messageTexts[this.conversations[0].id] = this.messageText;
          this.messageText = this.conversationService.messageTexts[current.id];
        }
        this.conversations = current ? [current] : null;
        this.spectator = this.conversations ? this.conversationService.isSpectator(this.conversations[0]) : false;
      })
    }

    this.renderer.listen(this.element.nativeElement, 'paste', (event: ClipboardEvent) => {
      const items = event.clipboardData.items;
      for (const index in items) {
        const item = items[index];
        if (item && item.kind === 'file') {
          const blob = item.getAsFile();
          const fileSet: Set<File> = new Set();
          fileSet.add(blob);
          this._fileDialog(fileSet);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.currentConversationSubscription && this.currentConversationSubscription.unsubscribe();
  }

  onClickSend($event) {
    if (!$event.metaKey && !$event.ctrlKey) {
      if (this.conversations && this.conversations.length > 0 && this.messageText) {
        this.conversations.forEach(conversation => {
          const message: MessageBean = {message: this.messageText.trim()};
          if (this.replyTo) {
            message.replyTo = this.replyTo;
          }
          this.messageService.postMessage(conversation.id, message).subscribe((message: MessageBean) => {
            this.messageText = "";
          });
          this.sent.emit();
        });
      }
    }
  }

  onClickAttach() {
    this.file.nativeElement.click();
  }

  onFileAdded() {
    const fileSet: Set<File> = new Set();
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (let key in files) {
      if (!isNaN(parseInt(key))) {
        fileSet.add(files[key]);
      }
    }
    this._fileDialog(fileSet);
  }

  _fileDialog(fileSet: Set<File>) {
    const dialogRef = this.dialog.open(DialogFileDialogComponent, {
      width: '600px',
      data: fileSet
    });
    dialogRef.afterClosed().subscribe((result: Set<FileMessage>) => {
      if (result) {
        let obs = new Observable(observer => {
          observer.next();observer.complete()
        });
        result.forEach(fm => {
          if (this.conversations && this.conversations.length > 0) {
            this.conversations.forEach(conversation => {
              obs = obs.pipe(concatMap(observer => this.messageService.postFileMessage(conversation.id, fm)));
            });
          }
        });

        this.fileLoading = true;
        obs.subscribe(() => {
          this.sent.emit();
        }, () => {
        }, () => {
          this.fileLoading = false;
        });
      }
    });
  }

  onFileClick(event) {
    this.file.nativeElement.value = "";
  }

  onClickNewLine($event: any) {
    if (($event.metaKey || $event.ctrlKey) && $event.key.toLowerCase() === 'enter') {
      this.messageText = this.messageText + '\n';
    }
  }
}
