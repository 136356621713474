import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  title;
  message;
  ok;
  field;
  fieldValue: string = '';
  required: boolean;
  type: string;
  min: string;
  max: string;

  //noinspection JSAnnotator
  constructor(public dialogRef: MatDialogRef<ConfirmationComponent>,
              private snackBarService: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.title = data.title;
    this.message = data.message;
    this.ok = data.ok;
    this.field = data.field;
    this.fieldValue = data.fieldValue || '';
    this.required = data.required || false;
    this.type = data.type;
    this.min = data.min;
    this.max = data.max;
  }

  ngOnInit() {
  }

  _validate(): boolean {
    if (this.type && this.type === 'number') {
      let num: number = Number(this.fieldValue);
      if (num < Number(this.min) || num > Number(this.max) || num % 1 != 0) {
        this.snackBarService.open('Ошибка. ' + this.field + ' должно быть целым числом: ' + this.min + ' - ' + this.max);
        return false;
      }
    }
    return true;
    // changes.prop contains the old and the new value...
  }

  onClickOk(event) {
    if (this.required && !(this.fieldValue.trim())) {
      this.snackBarService.open(this.field + ' обязательно для заполнения');
    } else if (this._validate()) {
      //noinspection TypeScriptUnresolvedFunction
      this.dialogRef.close({data: this.field ? this.fieldValue : 'ok'});
    }
  }

}
