import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: 'fullDateToTime'
})
export class FullDateToTimePipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    return moment(value).format("HH:mm:ss");
  }

}
