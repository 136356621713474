import {EventEmitter, Injectable} from '@angular/core';
import {WIALON_HOST, WIALON_HOST_API, WIALON_TOKEN_STORAGE_KEY} from "../common/constant/wialon";
import {forkJoin, Observable, of} from "rxjs";
import {UserService} from "./admin/user.service";
import {CompanyBean, UserBean, UserRole, UserStatus} from "@chat/common-model";
import {MatSnackBar} from "@angular/material";
import {SessionService} from "../common/auth/session.service";
import {UserExternalType} from "../../../projects/common-model/src/lib/constant";

declare const wialon: any;

@Injectable({
  providedIn: 'root'
})
export class WialonService {

  public wialonLoginEmitter: EventEmitter<string>;

  constructor(
    private userService: UserService,
    private sessionService: SessionService,
    private snankBar: MatSnackBar,
  ) {
    this.wialonLoginEmitter = new EventEmitter<string>();
  }

  public login() {
    // if not logged
    const token = this.getToken(); // get token from input
    if (!token) { // if token is empty - print message to log
      this.internalLogin();
      return;
    }

    const session = wialon.core.Session.getInstance(); // get instance of current Session
    console.log(session);
    var user = session.getCurrUser(); // get current User
    if (user) { // if user exists - you are already logged, print username to log
      this.wialonLoginEmitter.emit(token);
      return;
    }

    session.initSession(WIALON_HOST_API); // initialize Wialon session
    session.loginToken(token, "", // trying login
      (code) => { // login callback
        if (code) {
          this.internalLogin();
        } else {
          this.wialonLoginEmitter.emit(token);
        }
      }
    );
  }

  public loginSid(sid: string, userName: string): Observable<string> {
    return new Observable<string>((observer) => {
      const session = wialon.core.Session.getInstance(); // get instance of current Session
      session.initSession(WIALON_HOST_API); // initialize Wialon session

      session.duplicate(sid, userName ? userName : '', !0,
        (code) => { // login callback
          if (code) {
            observer.next(null);
          } else {
            const user = session.getCurrUser().getName();
            observer.next(user);
          }
          observer.complete();
        }
      );
    })
  }

  public loginAuthToken(token: string): Observable<string> {
    return new Observable<string>((observer) => {
      const session = wialon.core.Session.getInstance(); // get instance of current Session
      session.initSession(WIALON_HOST_API); // initialize Wialon session

      session.loginToken(token, "", (code) => {
        if (code) {
          observer.next(null);
        } else {
          const user = session.getCurrUser().getName();
          observer.next(user);
        }
        observer.complete();
      });
    })
  }

  private internalLogin() {
    // construct login page URL
    let url = WIALON_HOST + "/login.html"; // your site DNS + "/login.html"
    url += "?client_id=" + "Autochat";	// your application name
    url += "&access_type=" + 0x100;	// access level, 0x100 = "Online tracking only"
    url += "&activation_time=" + 0;	// activation time, 0 = immediately; you can pass any UNIX time value
    url += "&duration=" + 604800;	// duration, 604800 = one week in seconds
    url += "&flags=" + 0x1;			// options, 0x1 = add username in response

    url += "&redirect_uri=" + WIALON_HOST + "/post_token.html"; // if login succeed - redirect to this page

    // listen message with token from login page window
    window.addEventListener("message", this.tokenReceived.bind(this));

    // finally, open login page in new window
    window.open(url, "_blank", "width=760, height=500, top=300, left=500");
  }

  private tokenReceived(e) {
    const msg = e.data;
    if (typeof msg == "string" && msg.indexOf("access_token=") >= 0) {
      // get token
      const token = msg.replace("access_token=", "");
      localStorage.setItem(WIALON_TOKEN_STORAGE_KEY, token);

      // or login to wialon using our token
      const session = wialon.core.Session.getInstance();
      session.initSession("https://hst-api.wialon.com");

      session.loginToken(token, "", (code) => {
        if (code) {
          console.log(code, msg);
          this.snankBar.open("Не возможно залогиниться в Wialon");
          return;
        }
        const user = session.getCurrUser().getName();

        this.userService.updateExternalUser(this.sessionService.user, {
          id: user,
          type: UserExternalType.WIALON,
        }).subscribe(() => {
        });

        console.log("Authorized WIALON as " + user);
        console.log(session);
        this.wialonLoginEmitter.emit(token);
      });

      // remove "message" event listener
      window.removeEventListener("message", this.tokenReceived);
    }
  }

  public logout() {
    const session = wialon.core.Session.getInstance();
    if (session && session.getId()) {
      session.logout(function () {
        localStorage.removeItem(WIALON_TOKEN_STORAGE_KEY);
        this.wialonLoginEmitter.emit(null);
      });
    }
  }

  public getResources(): Observable<string[]> {
    return new Observable((observer) => {
      // flags to specify what kind of data should be returned
      const flags = wialon.util.Number.or(
        wialon.item.Item.dataFlag.base,
        wialon.item.Resource.dataFlag.drivers,
        wialon.item.Resource.dataFlag.driverUnits
      ); // 64 bit OR

      // Subscribe on resource data
      const session = wialon.core.Session.getInstance();
      session.updateDataFlags( // load items to current session
        [{type: "type", data: "avl_resource", flags: flags, mode: 0}], // Items specification
        function (code) { // updateDataFlags callback
          if (code) {
            console.log("Error: " + wialon.core.Errors.getErrorText(code));

            observer.error(wialon.core.Errors.getErrorText(code));
            observer.complete();
            return; // exit if error code
          }

          const ress = session.getItems("avl_resource"); // get loaded 'avl_resource's items
          console.log(ress);
          observer.next(ress.map(r => r.getId()));
          observer.complete();

        });


    });

  }

  public syncUsers(company: CompanyBean): Observable<number> {
    return new Observable((observer) => {
      const session = wialon.core.Session.getInstance();
      session.loadLibrary("resourceDrivers");

      this.getResources().subscribe((ids) => {


        const driverList = [];
        ids.filter((id) => !!id).forEach((id) => {
          const currentResource = session.getItem(id);
          const drivers = currentResource.getDrivers();
          Object.values(drivers).forEach((drv: any) => {
            drv.resourceId = id;
            driverList.push(drv);
          })
        });

        const usersForCreate = forkJoin(driverList.map((drv) => this.userService.getExternal(drv.id, true)));

        usersForCreate.subscribe((result: UserBean[]) => {
          const found = result.filter(r => !!r);

          const driversToCreate = driverList
                    .filter((drv) => !found.find((f: UserBean) => (f.external && drv.id.toString() === f.external.id)));

          if (driversToCreate.length > 0) {
            forkJoin(driversToCreate.map((drv) => {
                const currentResource = session.getItem(drv.resourceId);
                const driver = currentResource.getDriver(drv.id);

                return this.userService.create({
                  companyId: company.id,
                  role: UserRole.DRIVER,
                  fullName: driver.n,
                  contactName: driver.n,
                  userName: driver.n,
                  password: '1111',
                  phone: driver.p,
                  status: UserStatus.ACTIVE,
                  external: {
                    id: drv.id ? drv.id.toString() : '-',
                    type: UserExternalType.WIALON,
                  }
                });
              })
            ).subscribe((created: UserBean[]) => {
              observer.next(created.length);
              observer.complete();
            });
          } else {
            observer.next(0);
            observer.complete();
          }
        });


      })

    });
  }

  private getToken() {
    return localStorage.getItem(WIALON_TOKEN_STORAGE_KEY);
  }
}
