import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Url} from "../common/url";
import {HttpService} from "../common/http/http.service";

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  constructor(private httpService: HttpService) { }

  public getLastVersion(): Observable<{} | any> {
    return this.httpService.get<any>({url: Url.API_INFO_LAST_VERSION});
  }
}
