import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Url} from "../../../../common/url";
import {UserService} from "../../../../service/admin/user.service";
import {CompanyService} from "../../../../service/admin/company.service";
import {MatDialog, MatDialogConfig, MatDrawer, MatSnackBar} from "@angular/material";
import {ConfirmationComponent} from "../../../../common/confirmation/confirmation.component";
import {SessionService} from "../../../../common/auth/session.service";
import {
  CompanyPermission,
  ConversationBean,
  ConversationType,
  getConversationName,
  UserBean,
  UserLogsBean,
  UserRole
} from "@chat/common-model";
import {ConversationService} from "../../../../service/conversation.service";
import {MatSelectionList} from "@angular/material/list";
import {WialonService} from "../../../../service/wialon.service";
import {Subscription} from "rxjs";
import {LinkUserComponent} from "./wialon/link-user/link-user.component";
import {UserExternalType} from "../../../../../../projects/common-model/src/lib/constant";

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnDestroy {
  title: string;
  companyId: string;
  user: UserBean;
  conversations: ConversationBean[] = [];
  UserRole: any = UserRole;

  userList: UserBean[];
  allUsers: UserBean[];
  users: UserBean[] = [];

  userLogs: UserLogsBean[];

  filteredUsers: UserBean[];
  filteredUsersGroup: UserBean[];

  search: string;
  searchGroup: string;

  selectedForConversation: UserBean[] = [];
  usersLoaded: boolean = false;
  createPersonal: boolean = true;

  showPassword: boolean = false;

  @ViewChild(MatDrawer, {static: false}) drawer: MatDrawer;

  roles: UserRole[] = [UserRole.OPERATOR, UserRole.DRIVER, UserRole.SUPERVISOR];
  conversationDisplayedColumns: string[] = ['name', 'participants', 'type', 'actions'];
  userLogsDisplayedColumns: string[] = ['requester', 'requested', 'created', 'actions'];

  getConversationName: any = getConversationName;

  loaded: boolean = false;

  CompanyPermission: any = CompanyPermission;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private sessionService: SessionService,
              private userService: UserService,
              private companyService: CompanyService,
              private wialonService: WialonService,
              private conversationService: ConversationService) {
    if (this.isSuperAdmin()) {
      this.roles.push(UserRole.ADMIN);
    }
  }

  ngOnInit() {
    this.loaded = false;
    this.route.params.subscribe(params => {
      const {id, userId} = params;
      this.title = userId ? "Редактирование пользователя" : "Новый пользователь";
      if (userId) {
        this.userService.get(userId).subscribe((user: UserBean) => {
          this.companyId = user.companyId;
          this.user = user;

          this.getConversations();
        }, (error) => {
        }, () => {
          this.loaded = true
        });
      } else {
        const cid = id ? id : this.sessionService.user.companyId;
        this.companyId = cid;
        this.user = {};
        this.user.companyId = cid;
        this.user.role = UserRole.DRIVER;
        this.loaded = true;
      }
    });

  }

  ngOnDestroy(): void {
  }

  getConversations() {
    if (this.user && this.user.id) {
      this.userService.getConversationList(this.user.id).subscribe((conversations: ConversationBean[]) => {
        this.conversations = conversations;
        this.getUsers();
        this.getUserLogs();
      });
    }
  }

  getUsers() {
    if (this.user && this.user.id) {
      if (this.isSuperAdmin()) {
        this.companyService.getUserList(this.user.companyId).subscribe((users: UserBean[]) => {
          this._getUsers(users);
        });
      } else {
        this.userService.getList().subscribe((users: UserBean[]) => {
          this._getUsers(users);
        })
      }
    }
  }

  getUserLogs() {
    if (this.user && this.user.id) {
        this.userService.logsList(this.user).subscribe((userLogs: UserLogsBean[]) => {
          this.userLogs = userLogs.sort((a, b) => {
            const aName = a.requested;
            const bName = b.requested;
            if (aName > bName) {
              return 1;
            }
            if (bName > aName) {
              return -1;
            }
            return 0;
          });
        })
    }
  }

  _getUsers(users: UserBean[]) {
    this.userList = users;
    this.allUsers = users.filter(u => u.id !== this.user.id && [UserRole.DRIVER, UserRole.OPERATOR].includes(u.role)).sort((a, b) => {
      const nameA = a.contactName ? a.contactName.toUpperCase() : '';
      const nameB = b.contactName ? b.contactName.toUpperCase() : '';
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    this.filteredUsersGroup = this.allUsers;
    const directConversations = this.conversations.filter(c => c.type === ConversationType.DIRECT && c.participants.find(p => p.id === this.user.id));
    this.users = this.allUsers.filter(u => !directConversations.find(c => c.participants.find(p => p.id === u.id)));
    this.filteredUsers = this.users;
    //this.users = this.allUsers.filter(u => !this.conversations.find(c => c.participants.find(p => p.id === u.id)));
    this.usersLoaded = true;
  }

  onClickClose() {
    this.router.navigate([this.isSuperAdmin() ? Url.ROUTE_ADMIN_COMPANY(this.companyId) : Url.ROUTE_USERS]);
  }

  onClickSave() {
    if (this._validate()) {
      this.userService.save(this.user).subscribe((user: UserBean) => {
        this.snackBar.open("Пользователь успешно сохранен");
        this.router.navigate([this.isSuperAdmin() ? Url.ROUTE_ADMIN_USER(user.id) : Url.ROUTE_USER(user.id)]);
      })
    }
  }

  onClickAddConversation() {
    this.router.navigate([Url.ROUTE_ADMIN_USER_CONVERSATION(this.user.id, "new")]);
  }

  onClickEditConversation(conversation: ConversationBean) {
    this.router.navigate([
      this.isSuperAdmin() ? Url.ROUTE_ADMIN_USER_CONVERSATION(this.user.id, conversation.id) : Url.ROUTE_USER_CONVERSATION(this.user.id, conversation.id)
    ]);
  }


  onClickDeleteConversation(conversation: ConversationBean) {
    const config = new MatDialogConfig();
    config['data'] = {
      title: 'Удаление',
      message: 'При удалении чата будут удалены сообщения из него. Вы действительно хотите удалить чат?',
      ok: 'Удалить'
    };
    const dialogRef = this.dialog.open(ConfirmationComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['data'] === 'ok') {
        this.userService.removeConversation(this.user, conversation).subscribe(
          deleted => {
            this.snackBar.open('Чат ' + (conversation.name ? '"' + conversation.name + '"' : '') + ' удален');
            this.getConversations();
          }
        );
      }
    });
  }

  onClickAddConversationList(list: any) {
    if (list && list.selectedOptions && list.selectedOptions.selected.length > 0) {
      let count = 0;
      list.selectedOptions.selected.forEach(selected => {
        this.userService.createConversation(this.user.id, {
          type: ConversationType.DIRECT,
          participants: [
            {id: this.user.id, fullName: this.user.fullName, role: this.user.role, contactName: this.user.contactName},
            selected.value
          ]
        }).subscribe((conversation: ConversationBean) => {
          count++;
          if (count === list.selectedOptions.selected.length) {
            this.snackBar.open("Чаты были созданы");
            this.getConversations();
            this.drawer.close();
            this.usersLoaded = false;
          }
        });
      })
    }

  }

  onDrawerOpened() {
    this.getUsers();
  }

  private isSuperAdmin(): boolean {
    return this.sessionService.user && this.sessionService.user.role && this.sessionService.user.role === UserRole.SUPERADMIN;
  }

  onClickAddGroupConversation(list: any) {
    if (list && list.selectedOptions && list.selectedOptions.selected.length > 0) {
      let participants = list.selectedOptions.selected.map(selected => selected.value);
      participants.unshift(this.user);
      this.userService.createConversation(this.user.id, {
        type: ConversationType.GROUP,
        name: "Группа",
        participants: participants
      }).subscribe((conversation: ConversationBean) => {
        this.snackBar.open("Групповой чат создан");
        this.getConversations();
        this.drawer.close();
        this.usersLoaded = false;
      });
    }
  }


  onClickClearSearch() {
    this.search = null;
    this.onChangeSearch();
  }

  onChangeSearch() {
    if (this.search) {
      this.filteredUsers = this.users.filter(option => new RegExp(this.search, 'gi')
        .test(option.fullName + " " + option.contactName));
    } else {
      this.filteredUsers = this.users;
    }
  }


  onClickClearSearchGroup() {
    this.searchGroup = null;
    this.onChangeSearchGroup();
  }

  onChangeSearchGroup() {
    if (this.searchGroup) {
      this.filteredUsersGroup = this.allUsers.filter(option => new RegExp(this.searchGroup, 'gi')
        .test(option.fullName + " " + option.contactName));
    } else {
      this.filteredUsersGroup = this.allUsers;
    }
  }

  isParticipant(conversation: ConversationBean) {
    return conversation.participants ? conversation.participants.find(p => p.id === this.user.id) : false;
  }

  isSpectator(conversation: ConversationBean) {
    return conversation.spectators ? conversation.spectators.find(p => p.id === this.user.id) : false;
  }

  onClickDeleteSpectator(conversation: ConversationBean) {
    if (conversation && conversation.spectators) {
      const config = new MatDialogConfig();
      config['data'] = {
        title: 'Удаление наблюдателя',
        message: 'Наблюдатель будет удален из чата. Вы уверены?',
        ok: 'Удалить'
      };
      const dialogRef = this.dialog.open(ConfirmationComponent, config);
      dialogRef.afterClosed().subscribe(result => {
        if (result && result['data'] === 'ok') {
          conversation.spectators = conversation.spectators.filter(s => s.id !== this.user.id);
          this.userService.updateConversation(this.user.id, conversation).subscribe(() => {
            this.conversations = this.conversations.filter(c => c.id !== conversation.id);
            this.snackBar.open("Наблюдатель удален из чата");
          });
        }
      });
    }
  }

  _validate(): boolean {
    if (!this.user) {
      this.snackBar.open("Пользователь не существует", null, {
        panelClass: "error"
      });
      return false;
    }
    if (!this.user.contactName && [UserRole.OPERATOR, UserRole.DRIVER].includes(this.user.role)) {
      this.snackBar.open("Необходимо заполнить название контакта", null, {
        panelClass: "error"
      });
      return false;
    }
    return true;
  }

  onClickSelectAll(selectedForConversation: MatSelectionList, role: UserRole) {
    selectedForConversation.selectedOptions.select(...selectedForConversation.options.filter(o => o.value.role === role));
  }

  onClickDeselectAll(selectedForConversation: MatSelectionList) {
    selectedForConversation.deselectAll();
  }

  onClickLogsRequest() {
    this.userService.logsRequest(this.user).subscribe(() => {
      this.snackBar.open("Логи пользователя запрошены");
    })
  }

  onClickDownloadLogs(userLogs: UserLogsBean) {
    if (userLogs.url) {
      this.userService.getLogsFile(userLogs).subscribe(result => {
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        const blob = new Blob([result], {type: 'text/plain'});
        link.href = URL.createObjectURL(blob);
        link.download = userLogs.id + '.zip';
        link.click();
      }, () => {
        this.snackBar.open("Ошибка загрузки файла");
      });
    }
  }

  onClickWialonLogin() {
    this.wialonService.login();
  }

  onClickWialonLinkUser() {
    const dialogRef = this.dialog.open(LinkUserComponent, {});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.updateExternalUser(this.user, {
          id: result,
          type: UserExternalType.WIALON,
        }).subscribe(() => {
          this.snackBar.open("Пользователь привязан");
        });
      }
    });
  }
}

