import {Component, OnInit} from '@angular/core';
import {ConversationService} from "../../../service/conversation.service";
import {
  ConversationBean, MessageBean, MessageListBean, MessagePushStatusType,
  MessageStatusType, UserInfoBean, UserConnectionType
} from "@chat/common-model";
import {StatusService} from "../../../service/status.service";
import {MessageService} from "../../../service/message.service";
import * as moment from "moment";
import {SessionService} from "../../../common/auth/session.service";
import {UserService} from "../../../service/admin/user.service";
import {ConversationType} from "../../../../../projects/common-model/src/lib/constant";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {Subscription} from "rxjs";


@Component({
  selector: 'app-conversation-list',
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.scss']
})
export class ConversationListComponent implements OnInit {

  conversations: ConversationBean[] = [];
  filteredConversations: ConversationBean[] = [];
  conversationsLoaded: boolean = false;
  currentId: string;
  participants: UserInfoBean[] = [];

  UserConnectionType: any = UserConnectionType;
  ConversationType: any = ConversationType;
  MessageStatusType: any = MessageStatusType;
  MessagePushStatusType: any = MessagePushStatusType;

  userId: string;

  search: string;

  sortConversationSubscription: Subscription;
  currentConversationSubscription: Subscription;
  newMessagesSubscription: Subscription;
  postMessageSubscription: Subscription;
  statusUpdatedParticipantsSubscription: Subscription;
  statusUpdatedMessagesSubscription: Subscription;
  conversationCreatedSubscription: Subscription;
  seenMessagesSubscription: Subscription;

  constructor(private sessionService: SessionService,
              private conversationService: ConversationService,
              private messageService: MessageService,
              private userService: UserService,
              private statusService: StatusService) {
  }

  ngOnInit() {
    this.userId = this.sessionService.user.id;
    this.sortConversationSubscription = this.conversationService.sortConversationEmitter.subscribe(() => {
      this.sort();
    });
    this.getConversations();
    this.currentConversationSubscription = this.conversationService.currentConversationEmitter.subscribe((current: ConversationBean) => {
      if (current) {
        this.currentId = current.id;
        // current.unreadCount = 0; - wrong set
      } else {
        this.currentId = null;
      }
    });
    this.newMessagesSubscription = this.statusService.newMessagesEmitter.subscribe((messageList: MessageListBean) => {
      const conversation = this._findConversation(messageList.conversation.id);
      if (conversation) {
        if (messageList.conversation.id !== this.currentId) {
          conversation.unreadCount = isNaN(conversation.unreadCount) ? messageList.messages.length : conversation.unreadCount + messageList.messages.length;
        }
        let lastMessage: MessageBean = conversation.lastMessage;
        messageList.messages.forEach(m => {
          if (!lastMessage || moment.utc(m.created).isAfter(moment.utc(lastMessage.created))) {
            lastMessage = m;
          }
        });
        if (lastMessage) {
          conversation.lastMessage = lastMessage;
        }
        this.sort();
      }
      this.messageService.play();
    });
    this.postMessageSubscription = this.messageService.postMessageEmitter.subscribe((message: MessageBean) => {
      const conversation = this._findConversation(message.conversation.id);
      if (conversation) {
        conversation.lastMessage = message;
        this.sort();
      }
    });
    this.statusUpdatedParticipantsSubscription = this.statusService.statusUpdatedParticipantsEmitter.subscribe((messageList: MessageListBean) => {
      if (messageList && messageList.conversation) {
        const s = messageList.conversation;
        let cs = this.conversations.find(c => c.id === s.id);
        if (cs) {
          if (s.participants) {
            for (const participant of cs.participants) {
              const up = s.participants.find(sp => sp.id === participant.id);
              if (up) {
                participant.lastConnection = up.lastConnection;
                participant.connectionStatus = up.connectionStatus;
              }
            }
          }
          cs.status = s.status;
          cs.lastConnection = s.lastConnection;
        }
      }
    });
    this.statusUpdatedMessagesSubscription = this.statusService.statusUpdatedMessagesEmitter.subscribe((messageList: MessageListBean) => {
      const conversation = this._findConversation(messageList.conversation.id);
      if (conversation && conversation.lastMessage) {
        if (messageList.messages) {
          const message = messageList.messages.find(m => m.id === conversation.lastMessage.id);
          if (message) {
            conversation.lastMessage.status = message.status;
            conversation.lastMessage.pushStatus = message.pushStatus;
            conversation.lastMessage.pushed = message.pushed;
          }
        }
      }
    });
    this.seenMessagesSubscription = this.statusService.seenMessagesEmitter.subscribe((seenMessages: MessageListBean) => {
      const conversation = this._findConversation(seenMessages.conversation.id);
      if (conversation) {
        const from = moment.utc().subtract(5, "years").toISOString();
        this.messageService.getUnSeenMessageList(conversation.id, from).subscribe((newMessageList: MessageListBean) => {
          if (newMessageList && newMessageList.messages) {
            conversation.unreadCount = newMessageList.messages.length;
          }
        });
      }
/*      if (conversation && seenMessages.messages) {
        conversation.unreadCount = conversation.unreadCount < seenMessages.messages.length ? 0 : conversation.unreadCount - seenMessages.messages.length;
      }*/
    });
    this.conversationCreatedSubscription = this.userService.conversationCreatedEmitter.subscribe(() => {
      this.getConversations();
    });
  }

  _findConversation(conversationId: string): ConversationBean {
    return this.conversations.find(c => c.id === conversationId ||(c.extraConversations && c.extraConversations[this.userId] && c.extraConversations[this.userId].find(ec => ec.id === conversationId)));
  }

  getConversations() {
    this.conversationService.getConversationList().subscribe((list: ConversationBean[]) => {
      const extraConversationIds: string[] = [];
      const from = moment.utc().subtract(5, "years").toISOString();
      list.forEach(c => {
        if (c.participants) {
          c.participants.forEach(p => {
            if (!this.participants.find(ps => ps.id === p.id)) {
              this.participants.push(p);
            }
          })
        }

        if (c.extraConversations && c.extraConversations[this.userId]) {
          for (const extraConversation of c.extraConversations[this.userId]) {
            extraConversationIds.push(extraConversation.id);
          }
        }
        this.messageService.getUnSeenMessageList(c.id, from).subscribe((newMessageList: MessageListBean) => {
          if (newMessageList && newMessageList.messages) {
            c.unreadCount = newMessageList.messages.length;
            // c.lastMessage = newMessageList.messages.sort((a, b) => {return moment(b.created).valueOf() - moment(a.created).valueOf()})[0];
          }
        });
      });
      if (extraConversationIds.length > 0) {
        list = list.filter(l => !extraConversationIds.includes(l.id))
      }
      this.conversations = list;
      this.sort();

      //this.statusService.getUserStatuses();
    }, () => {
    }, () => {
      this.conversationsLoaded = true;
    });
  }

  onClickSelectConversation(conversation: ConversationBean) {
    this.conversationService.selectCurrentConversation(conversation);
  }

  sort() {
    if (!this.conversationService.alphabet) {
      const oldTime = moment().utc().subtract(10, 'years');
      this.conversations.sort((a, b) => {
        let aCreated: moment.Moment = a.lastMessage && a.lastMessage.created ? moment.utc(a.lastMessage.created) : oldTime;
        let bCreated: moment.Moment = b.lastMessage && b.lastMessage.created ? moment.utc(b.lastMessage.created) : oldTime;
        if (aCreated.isBefore(bCreated)) {
          return 1;
        }
        if (bCreated.isBefore(aCreated)) {
          return -1;
        }
        return 0;
      });
    } else {
      this.conversations.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
    }

    this.onChangeSearch();
  }

  onClickClearSearch() {
    this.search = null;
    this.onChangeSearch();
  }

  onChangeSearch() {
    if (this.search) {
      this.filteredConversations = this.conversations.filter(option => new RegExp(this.search, 'gi')
        .test(option.name));
    } else {
      this.filteredConversations = this.conversations;
    }
  }

}
