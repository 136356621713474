import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionService} from "../session.service";
import {Url} from "../../url";

@Injectable()
export class AuthRolesGuard implements CanActivate {

  constructor(private sessionService: SessionService, private router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot): boolean|Observable<boolean> {
    console.log('[AuthRolesGuard]');

    const expectedRoles: string[] = route.data['expectedRole'];

    if (this.sessionService.isAuthenticated) {
      return new Observable((observer) => {
        if (!expectedRoles || expectedRoles.indexOf(this.sessionService.user.role) > -1) {
          console.log('AuthRolesGuard: role checked');
          observer.next(true);
        } else {
          console.log('AuthRolesGuard: redirect to forbidden');
          this.router.navigate([Url.ROUTE_FORBIDDEN]);
          observer.next(false);
        }
        observer.complete();
      });
    } else if (this.sessionService.isEnabled) {
      console.log('AuthUserGuard: navigate to signin');
      this.router.navigate([Url.ROUTE_SIGNIN]);
      return false;
    }
  }
}
