import { Pipe, PipeTransform } from '@angular/core';
import {UserRole} from "@chat/common-model";

@Pipe({
  name: 'userRole'
})
export class UserRolePipe implements PipeTransform {

  transform(value: UserRole, ...args: any[]): any {
    let text;
    switch (value) {
      case UserRole.DRIVER:
        text = "Водитель";break;
      case UserRole.OPERATOR:
        text = "Оператор";break;
      case UserRole.MECHANIC:
        text = "Механик";break;
      case UserRole.SUPERVISOR:
        text = "Руководитель";break;
      case UserRole.ADMIN:
        text = "Администратор";break;
      case UserRole.SUPERADMIN:
        text = "Супер-админ";break;
    }
    return text;
  }

}
