import {Component, Input, OnInit} from '@angular/core';
import {MessageBean, MessageType} from "@chat/common-model";
import {MessageService} from "../../../../../service/message.service";
import {DomSanitizer} from "@angular/platform-browser";
import {StreamState} from "../../../../../service/audio.service";

@Component({
  selector: 'app-dialog-conversation-message-voice',
  templateUrl: './dialog-conversation-message-voice.component.html',
  styleUrls: ['./dialog-conversation-message-voice.component.scss']
})
export class DialogConversationMessageVoiceComponent implements OnInit {

  @Input("message") message: MessageBean;
  audioUrl: any;
  audioObj = new Audio();
  duration;
  currentTime;

  files: Array<any> = [];
  state: StreamState = {
    playing: false,
    readableCurrentTime: '',
    readableDuration: '',
    duration: undefined,
    currentTime: undefined,
    canplay: false,
    error: false,
  };

  constructor(private sanitizer: DomSanitizer,
              private messageService: MessageService) {
  }

  ngOnInit() {
    if (MessageType.VOICE === this.message.type) {
      this.audioObj.addEventListener("canplay", () => {
        this.state.duration = this.audioObj.duration;
        this.state.canplay = true;
      });
      this.audioObj.addEventListener("error", () => {
        this.state.error = true;
        this.state.canplay = false;
      });
      this.audioObj.addEventListener("timeupdate", () => {
        this.state.currentTime = this.audioObj.currentTime;
      });
      this.audioObj.addEventListener("ended", () => {
        this.state.playing = false;
        this.audioObj.currentTime = 0;
        this.state.currentTime = 0;
      });
      this.messageService.getFile(this.message).subscribe(result => {
        const _arrayBufferToUrl = ( buffer, type ) => {
          const arrayBufferView = new Uint8Array( buffer );
          const blob = new Blob( [ arrayBufferView ], { type: type } );
          const urlCreator = window.URL;
          return urlCreator.createObjectURL( blob );
        };
        this.setUrl(_arrayBufferToUrl(result, this.message.mimeType));
      });
    }
  }


  pause() {
    this.state.playing = false;
    this.audioObj.pause();
  }

  play() {
    this.state.playing = true;
    this.audioObj.play();
  }

  onSliderChangeEnd(change) {
    this.audioObj.currentTime = change.value;
  }

  setUrl(url) {
    this.audioObj.src = url;
    this.audioObj.load();
    this.audioUrl = url;
  }

}
