import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ConversationBean, UserBean, UserRole} from "@chat/common-model";
import {UserService} from "../../../../service/admin/user.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SessionService} from "../../../../common/auth/session.service";

@Component({
  selector: 'app-group-add-participants',
  templateUrl: './group-add-participants.component.html',
  styleUrls: ['./group-add-participants.component.scss']
})
export class GroupAddParticipantsComponent implements OnInit {

  usersLoaded: boolean = false;
  allUsers: UserBean[];
  filteredUsers: UserBean[];

  search: string;

  conversation: ConversationBean;

  @ViewChild('selectedForGroupConversation', { static: false }) selectedForGroupConversation: any;

  constructor(public dialogRef: MatDialogRef<GroupAddParticipantsComponent>,
              private userService: UserService,
              private sessionService: SessionService,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.conversation = data;
  }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.userService.getList().subscribe((users: UserBean[]) => {
      this._getUsers(users);
    })
  }

  _getUsers(users: UserBean[]) {
    this.allUsers = users.filter(u => [UserRole.DRIVER, UserRole.OPERATOR].includes(u.role)
      && !this.conversation.participants.find(p => p.id === u.id)
      && this.sessionService.user.id !== u.id);
    this.filteredUsers = this.allUsers;
    this.usersLoaded = true;
  }

  onClickCreateConversation(list: any) {
    if (list && list.selectedOptions && list.selectedOptions.selected.length > 0) {
      let participants = list.selectedOptions.selected.map(selected => selected.value);
      this.dialogRef.close(participants);
    }
  }

  onClickClearSearch() {
    this.search = null;
    this.onChangeSearch();
  }

  onChangeSearch() {
    if (this.search) {
      this.filteredUsers = this.allUsers.filter(option => new RegExp(this.search, 'gi')
        .test(option.fullName + " " + option.contactName));
    } else {
      this.filteredUsers = this.allUsers;
    }
  }

}
