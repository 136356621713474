import {Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {AuthService} from "./auth.service";
import {LoginBean, UserBean, UserRole} from "@chat/common-model";
import {catchError} from "rxjs/operators";
import {Url} from "../url";
import {MatSnackBar} from "@angular/material";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public isEnabled: boolean;
  public isAuthenticated: boolean;

  public user: UserBean;

  public returnRoute: string;

  constructor(private authService: AuthService, private snackBar: MatSnackBar) {
  }

  public loadSession(): Observable<number> {
    this.isAuthenticated = false;
    return new Observable((observer) => {
      this.authService.loadToken().subscribe(result => {
        observer.next(result);
        observer.complete();
      })
    })
  }

  public processUser(): Observable<number> {
    return new Observable((observer) => {
      this.authService.loadUserProfile().subscribe(profile => {
        console.log('User profile loaded');
        this.user = profile;

        this.isEnabled = true;
        this.isAuthenticated = true;

        observer.next(0);
        observer.complete();
      }, error => {

        this.isEnabled = true;
        this.isAuthenticated = false;

        observer.next(-1);
        observer.complete();
      });

    });
  }

  public processAnonymous() {
    this.user = null;
    this.isAuthenticated = false;
    this.isEnabled = true;
    this.authService.logout();
  }

  public login(loginBean: LoginBean): Observable<any> {
    return this.authService.login(loginBean).pipe(catchError<any, any>(val => this.handleError(this, val)));
  }

  public loginWialon(loginBean: LoginBean): Observable<any> {
    return this.authService.login(loginBean).pipe(catchError<any, any>(val => this.handleError(this, val)));
  }

  public logout(returnRoute?: string): Observable<any> {
    return new Observable(observer => {
      console.log("set return route: " + returnRoute);
      this.returnRoute = returnRoute;
      this.authService.logout();
      this.isAuthenticated = false;
      observer.next(0);
      observer.complete();
    });
  }

  public isSuperAdmin(): boolean {
    return this.user && this.user.role && this.user.role === UserRole.SUPERADMIN;
  }

  private handleError(that: any, error: any): Observable<any> {
    const {message} = error.error;
    if (message) {
      this.snackBar.open(message, null,  {panelClass: 'snack-error'});
    }
    return throwError(error.error || error);
  }
}
