import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ChatComponent} from "./page/chat/chat.component";
import {AnonymousGuard} from "./common/auth/guard/anonymous.guard";
import {SigninComponent} from "./page/signin/signin.component";
import {UserListComponent} from "./page/admin/user/list/user-list.component";
import {CompanyListComponent} from "./page/admin/company/list/company-list.component";
import {AuthUserWithRolesGuard} from "./common/auth/guard/auth-user-with-roles.guard";
import {CompanyEditComponent} from "./page/admin/company/edit/company-edit.component";
import {UserEditComponent} from "./page/admin/user/edit/user-edit.component";
import {ConversationEditComponent} from "./page/admin/conversation/edit/conversation-edit.component";
import {ForbiddenComponent} from "./page/forbidden/forbidden.component";
import {UserRole} from "@chat/common-model";
import {HistoryComponent} from "./page/admin/history/history.component";
import {AdminComponent} from "./page/admin/admin/admin.component";
import {WialonService} from "./service/wialon.service";
import {WialonComponent} from "./page/signin/wialon/wialon.component";
import { TrialComponent } from './page/trial/trial.component';
import { TrialUsersComponent } from './page/trial/trial-users/trial-users.component';


const routes: Routes = [
  {path: '', redirectTo: 'chat', pathMatch: 'full'},
  {
    path: 'chat',
    canActivate: [AuthUserWithRolesGuard], data: {expectedRole: [UserRole.OPERATOR, UserRole.DRIVER]},
    children: [
      {path: '', component: ChatComponent}
    ]
  },
  {
    path: 'users',
    canActivate: [AuthUserWithRolesGuard], data: {expectedRole: [UserRole.ADMIN]},
    children: [
      {path: '', component: UserListComponent},
      {path: 'new', component: UserEditComponent},
      {path: ':userId', children: [
          {path: '', component: UserEditComponent},
          {path: 'conversations/new', component: ConversationEditComponent},
          {path: 'conversations/:conversationId', component: ConversationEditComponent}
        ]}
    ]
  },
  {
    path: 'history',
    canActivate: [AuthUserWithRolesGuard], data: {expectedRole: [UserRole.SUPERVISOR,UserRole.ADMIN, UserRole.SUPERADMIN]},
    children: [
      {path: '', component: HistoryComponent}
    ]
  },
  {
    path: 'admin',
    canActivate: [AuthUserWithRolesGuard], data: {expectedRole: [UserRole.SUPERADMIN]},
    children: [
      {path: '', component: CompanyListComponent},
      {path: 'new', component: CompanyEditComponent},
      {path: 'admin', component: AdminComponent},
      {path: ':id', children: [
        {path: '', component: CompanyEditComponent},
        {path: 'users/new', component: UserEditComponent}
      ]},
      {path: 'users', children: [
        {path: ':userId', children: [
            {path: '', component: UserEditComponent},
            {path: 'conversations/new', component: ConversationEditComponent},
            {path: 'conversations/:conversationId', component: ConversationEditComponent}
        ]}
      ]},
    ]
  },
  {path: 'signin', canActivate: [AnonymousGuard], component: SigninComponent},
  {
    path: 'trial',
    children: [
      {path: '', component: TrialComponent},
      {path: ':id', component: TrialUsersComponent},
    ]
  },
  {path: 'wialon', component: WialonComponent},
  {path: 'forbidden', component: ForbiddenComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
