import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from "../auth/session.service";
import {Url} from "../url";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {ConversationBean, ConversationType, UserBean, UserRole} from "@chat/common-model";
import {MatDialog, MatDialogConfig, MatSnackBar} from "@angular/material";
import {GroupNameComponent} from "../../page/chat/group/group-name/group-name.component";
import {GroupParticipantsComponent} from "../../page/chat/group/group-participants/group-participants.component";
import {UserService} from "../../service/admin/user.service";
import {BatchComponent} from "../../page/chat/batch/batch.component";
import {ConfigService} from "../../service/config.service";
import {ChatViewType} from "../constant/config";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {ConversationService} from "../../service/conversation.service";
import {filter} from "rxjs/operators";

const SORT_STORAGE_KEY = 'chat-conversation-sort';
const SORT_STORAGE_ALPHABET = 'alphabet';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input("name") name: string = '';
  @Input("title") title: string = '';

  username: string;
  role: UserRole;

  UserRole: any = UserRole;
  Url: any = Url;
  chatView: ChatViewType;
  ChatViewType: any = ChatViewType;

  alphabet: boolean = false;

  constructor(private sessionService: SessionService,
              private userService: UserService,
              public configService: ConfigService,
              public conversationService: ConversationService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    if (localStorage) {
      const alphabet = localStorage.getItem(SORT_STORAGE_KEY);
      if (alphabet === SORT_STORAGE_ALPHABET) {
        this.alphabet = true;
      }
    }
    this.conversationService.sortConversationEmitter.emit(this.alphabet);
    if (this.sessionService.user) {
      this.username = this.sessionService.user.fullName || this.sessionService.user.userName || "";
      this.role = this.sessionService.user.role;
    }
    this.chatView = this.configService.chatView;
  }

  onClickSignout($event) {
    this.sessionService.logout().subscribe(() => {
      this.router.navigate([Url.ROUTE_SIGNIN]);
    });
  }

  onClickAddGroup($event) {
    const config = new MatDialogConfig();
    const dialogRef = this.dialog.open(GroupNameComponent, config);
    dialogRef.afterClosed().subscribe(groupName => {
      if (groupName) {
        const configParticipants = new MatDialogConfig();
        configParticipants.data = groupName;
        const dialogParticipantsRef = this.dialog.open(GroupParticipantsComponent, configParticipants);
        dialogParticipantsRef.afterClosed().subscribe((participants: UserBean[]) => {
          if (participants && participants.length > 0) {
            this.userService.createConversation(this.sessionService.user.id, {
              type: ConversationType.GROUP,
              name: groupName,
              participants: participants
            }).subscribe((conversation: ConversationBean) => {
              this.snackBar.open("Групповой чат создан");
              this.userService.conversationCreatedEmitter.emit(conversation);
            });
          }

        })
      }
    });
  }

  onClickBatchSend($event) {
    const config = new MatDialogConfig();
    const dialogRef = this.dialog.open(BatchComponent, config);
    dialogRef.afterClosed().subscribe(groupName => {
    });
  }

  onClickChangeChatView(chatView: ChatViewType) {
    this.configService.setChatView(chatView);
  }

  onChangeSort(event: MatSlideToggleChange) {
    this.alphabet = event.checked;
    this.conversationService.sortConversationEmitter.emit(this.alphabet);
    if (localStorage) {
      localStorage.setItem(SORT_STORAGE_KEY, event.checked ? SORT_STORAGE_ALPHABET : '');
    }
  }
}
