import { Component } from '@angular/core';
import {ConversationEditExtraListComponent} from "../extra-list/conversation-edit-extra-list.component";
import {ConversationBean} from "@chat/common-model";

@Component({
  selector: 'app-conversation-edit-extra-in-list',
  templateUrl: '../extra-list/conversation-edit-extra-list.component.html'
})
export class ConversationEditExtraInListComponent extends ConversationEditExtraListComponent{

  title: string = 'Список чатов, к которому присоединен текущий';

  onClickDeleteExtraConversation(conversation: ConversationBean) {
    if (conversation) {
      conversation.extraConversations[this.user.id] = conversation.extraConversations[this.user.id].filter(ec => ec.id !== this.conversation.id);
      this.conversations = [];
      this.userService.saveConversation(this.user.id, conversation).subscribe((conversation: ConversationBean) => {
        this.snackBar.open("Чаты успешно разъеденены");
        this.extraConversationsTable.renderRows();
      });
    }
  }
}
