import {Injectable} from '@angular/core';
import {Observable, of, throwError} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SessionService} from "../auth/session.service";
import {Url} from "../url";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material";
import {catchError} from "rxjs/operators";
import {Options} from "./http";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  headers: HttpHeaders;

  constructor(private http: HttpClient,
              private sessionService: SessionService,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private router: Router) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  public get<T>(options: Options): Observable<{} | T> {
    options = options || {};
    return this.http.get<T>(options.url, {
      headers: this._getHeaders(options),
      params: options.search, responseType: options.responseType
    }).pipe(catchError<T, any>(val => options.quiet ? of(null) : this.handleError(this, val)));
  }

  post<T>(options): Observable<{} | T> {
    options = options || {};
    let headers = this._getHeaders(options);
    if (options.noContentType) {
      headers = headers.delete('Content-Type');
    }
    return this.http
      .post<T>(options.url, options.isSendObject ? options.body : JSON.stringify(this._getBean(options.body)), {headers: headers}).pipe(catchError<T, any>(val => this.handleError(this, val)));
  }

  put<T>(options): Observable<{} | T> {
    options = options || {};
    return this.http
      .put<T>(options.url, options.body ? JSON.stringify(this._getBean(options.body)) : {}, {headers: this._getHeaders(options)}).pipe(catchError<T, any>(val => this.handleError(this, val)));
  }

  del<T>(options): Observable<{} | T> {
    options = options || {};
    return this.http.delete<T>(options.url, {headers: this._getHeaders(options)}).pipe(catchError<T, any>(val => this.handleError(this, val)));
  }

  postMultipart<T>(options): Observable<{} | T> {
    return this.http
      .post<T>(options.url, options.body, {}).pipe(catchError<T, any>(val => this.handleError(this, val)));
  }

  private _getHeaders(options) {
    let headers: HttpHeaders = this.headers;
    if (options.hasOwnProperty('headers')) {
      for (const h in options.headers) {
        if (options.headers.hasOwnProperty(h)) {
          headers = headers.set(h, options.headers[h]);
        }
      }
    }
    return headers;
  }

  private handleError(that: any, error: any): Observable<any> {
    const {message} = error.error;
    if (error.status === 401) {
      this.sessionService.logout(this.router.url).subscribe(() => {
        this.router.navigate([Url.ROUTE_SIGNIN]);
      });
    }
    if (message) {
      this.snackBar.open(message, null,  {panelClass: 'snack-error'});
    }
    return throwError(error.error || error);
  }

  private _getBean(body: any): any {
    if (body) {
      if (typeof body.getBean === 'function') {
        return body.getBean();
      } else {
        return body;
      }
    } else {
      return {};
    }
  }
}
