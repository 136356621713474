import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Url} from "../../common/url";
import {SessionService} from "../../common/auth/session.service";
import {LoginBean, LoginClientType} from "@chat/common-model";

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  signin: LoginBean = {
    password: "",
    username: "",
    client: LoginClientType.WEB,
  };

  constructor(private sessionService: SessionService,
              private router: Router
  ) { }

  ngOnInit() {
  }

  onClickSignin() {
    const {username, password} = this.signin;
    if (username && password) {
      this.sessionService.login(this.signin).subscribe(parsedToken => {
        let route;
        if (this.sessionService.returnRoute) {
          route = this.sessionService.returnRoute;
        } else {
          route = Url.getRouteByRole(parsedToken.role);
        }
        this.router.navigate([route]);
      });
    }
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      this.onClickSignin();
    }
  }
}
