import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig, MatSnackBar} from "@angular/material";
import {Router} from "@angular/router";
import {Url} from "../../../../common/url";
import {ConfirmationComponent} from "../../../../common/confirmation/confirmation.component";
import {UserService} from "../../../../service/admin/user.service";
import {UserBean, UserConnectionType, CompanyPermission} from "@chat/common-model";
import {InfoService} from "../../../../service/info.service";
import * as moment from "moment";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Subscription} from "rxjs";
import {WialonService} from "../../../../service/wialon.service";
import {SessionService} from "../../../../common/auth/session.service";

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {


  users: UserBean[] = [];
  filteredUsers: UserBean[] = [];

  search: string;
  userDisplayedColumns: string[] = ['role-icon', 'contactName', 'status', 'lastConnected', 'lastPushConnected', 'version', 'actions'];

  lastVersion: string;
  UserConnectionType: any = UserConnectionType;
  CompanyPermission: any = CompanyPermission;

  dataSource = new MatTableDataSource(this.filteredUsers);
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  wialonLoginSubscription: Subscription;
  wialonLoggedIn: boolean = false;

  constructor(private userService: UserService,
              private infoService: InfoService,
              private sessionService: SessionService,
              private wialonService: WialonService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private router: Router) { }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.infoService.getLastVersion().subscribe((lastVersion) => {
      this.lastVersion = lastVersion.version;
    })

    this.wialonLoginSubscription = this.wialonService.wialonLoginEmitter.subscribe((token) => {
      this.wialonLoggedIn = !!token;
    });

    this.getUsers();
  }

  ngOnDestroy(): void {
    if (this.wialonLoginSubscription) this.wialonLoginSubscription.unsubscribe();
  }

  getUsers() {
    this.userService.getList().subscribe((list: UserBean[]) => {
      this.users = list;
      this.filteredUsers = this.users;
      this.dataSource.data = this.filteredUsers;
    })
  }

  onClickEdit(user: UserBean) {
    this.router.navigate([Url.ROUTE_USER(user.id)]);
  }

  onClickDelete(user: UserBean) {
    const config = new MatDialogConfig();
    config['data'] = {title: 'Удаление', message: 'При удалении пользователя будут удалены их сообщения. Вы действительно хотите удалить пользователя?', ok: 'Удалить'};
    const dialogRef = this.dialog.open(ConfirmationComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['data'] === 'ok') {
        this.userService.remove(user).subscribe(
          deleted => {
            this.snackBar.open('Пользователь \"' + user.fullName + '\" удален');
            this.getUsers();
          }
        );
      }
    });
  }

  onClickAdd() {
    this.router.navigate([Url.ROUTE_USER_NEW]);
  }

  onClickClearSearch() {
    this.search = null;
    this.onChangeSearch();
  }

  onChangeSearch() {
    if (this.search) {
      this.filteredUsers = this.users.filter(option => new RegExp(this.search, 'gi')
        .test(option.fullName + " " + option.contactName));
    } else {
      this.filteredUsers = this.users;
    }
    this.dataSource.data = this.filteredUsers;
  }

  getLastConnection(lastConnection: string, status: UserConnectionType) {
    if (lastConnection && status === UserConnectionType.OFFLINE) {
      const m = moment(lastConnection);
      const now = moment.utc();
      if (now.diff(m, "years") < 90) {
        const today = moment().startOf('day');
        let format = "kk:mm:ss";
        if (today.isAfter(m)) {
          format = "DD.MM, " + format;
        }
        return " c " + m.format(format);
      } else {
        return "";
      }
    }
  }

  getLastPushConnection(lastConnection: string) {
    if (lastConnection) {
      const m = moment(lastConnection);
      const now = moment.utc();
      if (now.diff(m, "years") < 90) {
        const today = moment().startOf('day');
        let format = "kk:mm:ss";
        if (today.isAfter(m)) {
          format = "DD.MM, " + format;
        }
        return m.format(format);
      } else {
        return "";
      }
    }
  }

  onClickOpenHistory() {
    this.router.navigate([Url.ROUTE_HISTORY]);
  }

  onClickWialonLogin() {
    this.wialonService.login();
  }

  onClickWialonSyncUsers() {
    this.wialonService.syncUsers({
      id: this.sessionService.user.companyId,
    }).subscribe((result) => {
      this.snackBar.open(`Пользователи синхронизорованы: ${result} создано`);
      this.getUsers();
    })
  }

  onClickWialonLogout() {
    this.wialonService.logout();
  }
}
