import { Component, OnInit } from '@angular/core';
import { TrialBean, UserBean } from '@chat/common-model';
import { TrialService } from '../../service/trial.service';
import { Router } from '@angular/router';
import { Url } from '../../common/url';

@Component({
  selector: 'app-trial',
  templateUrl: './trial.component.html',
  styleUrls: ['./trial.component.scss']
})
export class TrialComponent implements OnInit {

  trial: TrialBean = {
    contact: '',
    email: '',
    companyName: '',
  };

  users: UserBean[] = null;

  userDisplayedColumns: string[] = ['role-icon', 'contactName', 'password'];

  constructor(
    private router: Router,
    private trialService: TrialService,
  ) { }

  ngOnInit() {
  }

  onClickGenerate() {
    if (this.trial.contact && this.trial.email) {
      this.trialService.generate(this.trial).subscribe((result: UserBean[]) => {
        void this.router.navigate([Url.ROUTE_TRIAL_BY_COMPANY(this.trial.email)]);
      });
    }
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      this.onClickGenerate();
    }
  }

}
