import { Pipe, PipeTransform } from '@angular/core';
import {UserConnectionType, UserRole} from "@chat/common-model";

@Pipe({
  name: 'connectionStatus'
})
export class ConnectionStatusPipe implements PipeTransform {

  transform(value: UserConnectionType, ...args: any[]): any {
    let text;
    switch (value) {
      case UserConnectionType.ONLINE:
        text = "На связи";break;
      case UserConnectionType.OFFLINE:
        text = "Не в сети";break;
      default:
        text = "Нет данных";
    }
    return text;
  }

}
