import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionService} from "../session.service";
import {Url} from "../../url";

@Injectable()
export class AnonymousGuard  implements CanActivate {

  constructor(private sessionService: SessionService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean|Observable<boolean> {
    console.log('[AnonymousGuard]');
    if (this.sessionService.isAuthenticated) {
      console.log('AnonymousGuard: navigate to ROOT');
      const {role} = this.sessionService.user;
      const route = Url.getRouteByRole(role);
      console.log(route);
      this.router.navigate([route]);
      return false;
    } else if (this.sessionService.isEnabled) {
      console.log('AnonymousGuard: navigate to signin');
      //this.router.navigate([Url.ROUTE_SIGNIN]);
      return true;
    } else {
      console.log('AnonymousGuard: loading...');
      return new Observable((observer) => {
        this.sessionService.loadSession().subscribe(result => {
          if (result === 0) {
            this.sessionService.processUser();
            this.router.navigate([Url.ROUTE_ROOT]);
          } else {
            this.sessionService.processAnonymous();
          }
          console.log('AnonymousGuard: result: '+result);
          observer.next(result === -1);
          observer.complete();
        });

      });
    }
  }
}
