import { Component, OnInit } from '@angular/core';
import { UserBean } from '@chat/common-model';
import { ActivatedRoute, Router } from '@angular/router';
import { TrialService } from '../../../service/trial.service';

@Component({
  selector: 'app-trial-users',
  templateUrl: './trial-users.component.html',
  styleUrls: ['./trial-users.component.scss']
})
export class TrialUsersComponent implements OnInit {

  users: UserBean[] = null;

  userDisplayedColumns: string[] = ['role-icon', 'contactName', 'password'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private trialService: TrialService,
  ) { }

  ngOnInit() {
    const {id} = this.route.snapshot.params;
    if (id) {
      this.trialService.get(id).subscribe((result: UserBean[]) => {
        this.users = result;
      });
    }
  }

}
