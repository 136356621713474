import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {SessionService} from "./common/auth/session.service";
import {AuthService} from "./common/auth/auth.service";
import { ChatComponent } from './page/chat/chat.component';
import { SigninComponent } from './page/signin/signin.component';
import {AuthUserGuard} from "./common/auth/guard/auth-user.guard";
import {AnonymousGuard} from "./common/auth/guard/anonymous.guard";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatBadgeModule,
  MatButtonModule, MatButtonToggleModule,
  MatCardModule,
  MatChipsModule, MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule,
  MatSnackBarModule,
  MatTableModule,
  MatToolbarModule, MatTooltipModule
} from "@angular/material";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ConversationService} from "./service/conversation.service";
import {HttpService} from "./common/http/http.service";
import {AuthInterceptor} from "./common/http/auth.interceptor";
import { DialogHeaderComponent } from './page/chat/dialog-header/dialog-header.component';
import { ConversationListComponent } from './page/chat/conversation-list/conversation-list.component';
import {AuthRolesGuard} from "./common/auth/guard/auth-roles.guard";
import { UserListComponent } from './page/admin/user/list/user-list.component';
import { CompanyListComponent } from './page/admin/company/list/company-list.component';
import {AuthUserWithRolesGuard} from "./common/auth/guard/auth-user-with-roles.guard";
import { MenuComponent } from './common/menu/menu.component';
import { ConfirmationComponent } from './common/confirmation/confirmation.component';
import { CompanyEditComponent } from './page/admin/company/edit/company-edit.component';
import { UserEditComponent } from './page/admin/user/edit/user-edit.component';
import { UserStatusPipe } from './common/pipe/user-status.pipe';
import { UserRolePipe } from './common/pipe/user-role.pipe';
import { ConversationTypePipe } from './common/pipe/conversation-type.pipe';
import { ParticipantsPipe } from './common/pipe/participants.pipe';
import { ConversationEditComponent } from './page/admin/conversation/edit/conversation-edit.component';
import { ForbiddenComponent } from './page/forbidden/forbidden.component';
import { NameToFioPipe } from './common/pipe/name-to-fio.pipe';
import { NameToColorPipe } from './common/pipe/name-to-color.pipe';
import { DialogConversationComponent } from './page/chat/dialog-conversation/dialog-conversation.component';
import { DialogConversationMessageComponent } from './page/chat/dialog-conversation/dialog-conversation-message/dialog-conversation-message.component';
import { FullDateToTimePipe } from './common/pipe/full-date-to-time.pipe';
import { MsgStatusToIconPipe } from './common/pipe/msg-status-to-icon.pipe';
import { DialogPostComponent } from './page/chat/dialog-post/dialog-post.component';
import { UserRoleToIconPipe } from './common/pipe/user-role-to-icon.pipe';
import { ConnectionStatusPipe } from './common/pipe/connection-status.pipe';
import {MessageService} from "./service/message.service";
import {StatusService} from "./service/status.service";
import { DialogFileDialogComponent } from './page/chat/dialog-post/dialog-file-dialog/dialog-file-dialog.component';
import { HistoryComponent } from './page/admin/history/history.component';
import {MsgStatusToTextPipe} from "./common/pipe/msg-status-to-text.pipe";
import { MsgIdToShortTextPipe } from './common/pipe/msg-id-to-short-text.pipe';
import { FullDateToDateTimePipe } from './common/pipe/full-date-to-date-time.pipe';
import { MsgTypeToIconPipe } from './common/pipe/msg-type-to-icon.pipe';
import { AdminComponent } from './page/admin/admin/admin.component';
import { DialogConversationViewImageComponent } from './page/chat/dialog-conversation/dialog-conversation-view-image/dialog-conversation-view-image.component';
import {AudioService} from "./service/audio.service";
import {DialogConversationMessageVoiceComponent} from "./page/chat/dialog-conversation/dialog-conversation-message/dialog-conversation-message-voice/dialog-conversation-message-voice.component";
import { GroupNameComponent } from './page/chat/group/group-name/group-name.component';
import { GroupParticipantsComponent } from './page/chat/group/group-participants/group-participants.component';
import {ParticipantInfoPipe} from "./common/pipe/participant-info.pipe";
import { GroupDelParticipantsComponent } from './page/chat/group/group-del-participants/group-del-participants.component';
import { GroupAddParticipantsComponent } from './page/chat/group/group-add-participants/group-add-participants.component';
import { DialogConversationMessageInfoComponent } from './page/chat/dialog-conversation/dialog-conversation-message/info/dialog-conversation-message-info.component';
import {ClipboardModule} from "ngx-clipboard";
import { DialogConversationMessageForwardComponent } from './page/chat/dialog-conversation/dialog-conversation-message/forward/dialog-conversation-message-forward.component';
import { SpectatorsComponent } from './page/admin/conversation/edit/spectators/spectators.component';
import { LoaderComponent } from './common/loader/loader.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { HistoryVoiceComponent } from './page/admin/history/voice/history-voice.component';
import { AddRoleNameComponent } from './page/admin/conversation/edit/add-role-name/add-role-name.component';
import { EditMemberNameComponent } from './page/admin/conversation/edit/edit-member-name/edit-member-name.component';
import { BatchComponent } from './page/chat/batch/batch.component';
import { ParticipantsComponent } from './page/admin/conversation/edit/participants/participants.component';
import {ImageViewerModule} from "ng2-image-viewer";
import { TableConversationListComponent } from './page/chat/table/conversation-list/table-conversation-list.component';
import {MatExpansionModule} from "@angular/material/expansion";
import { TableMessageTableComponent } from './page/chat/table/message-table/table-message-table.component';
import {PushNotificationsModule} from "ng-push";
import { ReplyComponent } from './page/chat/reply/reply.component';
import { SelectConversationComponent } from './common/dialog/select-conversation/select-conversation.component';
import { PostMessageDialogComponent } from './common/dialog/post-message-dialog/post-message-dialog.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import { ConversationEditParticipantListComponent } from './page/admin/conversation/edit/lists/participant-list/conversation-edit-participant-list.component';
import { ConversationEditSpectatorListComponent } from './page/admin/conversation/edit/lists/spectator-list/conversation-edit-spectator-list.component';
import { ConversationEditExtraListComponent } from './page/admin/conversation/edit/lists/extra-list/conversation-edit-extra-list.component';
import { ConversationEditExtraInListComponent } from './page/admin/conversation/edit/lists/extra-in-list/conversation-edit-extra-in-list.component';
import {InfoService} from "./service/info.service";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSortModule} from "@angular/material/sort";
import { WialonComponent } from './page/signin/wialon/wialon.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import { HasPermissionDirective } from './common/auth/directive/has-permission.directive';
import { LinkUserComponent } from './page/admin/user/edit/wialon/link-user/link-user.component';
import { TrialComponent } from './page/trial/trial.component';
import { TrialService } from './service/trial.service';
import { TrialUsersComponent } from './page/trial/trial-users/trial-users.component';

@NgModule({
  declarations: [
    AppComponent,
    ChatComponent,
    SigninComponent,
    DialogHeaderComponent,
    ConversationListComponent,
    UserListComponent,
    CompanyListComponent,
    MenuComponent,
    ConfirmationComponent,
    CompanyEditComponent,
    UserEditComponent,
    UserStatusPipe,
    UserRolePipe,
    ConversationTypePipe,
    ParticipantsPipe,
    ConversationEditComponent,
    ForbiddenComponent,
    NameToFioPipe,
    NameToColorPipe,
    DialogConversationComponent,
    DialogConversationMessageComponent,
    FullDateToTimePipe,
    MsgStatusToIconPipe,
    MsgStatusToTextPipe,
    DialogPostComponent,
    UserRoleToIconPipe,
    ConnectionStatusPipe,
    DialogFileDialogComponent,
    HistoryComponent,
    MsgIdToShortTextPipe,
    FullDateToDateTimePipe,
    MsgTypeToIconPipe,
    ParticipantInfoPipe,
    AdminComponent,
    DialogConversationViewImageComponent,
    DialogConversationMessageVoiceComponent,
    GroupNameComponent,
    GroupParticipantsComponent,
    GroupDelParticipantsComponent,
    GroupAddParticipantsComponent,
    DialogConversationMessageInfoComponent,
    DialogConversationMessageForwardComponent,
    SpectatorsComponent,
    LoaderComponent,
    HistoryVoiceComponent,
    AddRoleNameComponent,
    EditMemberNameComponent,
    BatchComponent,
    ParticipantsComponent,
    TableConversationListComponent,
    TableMessageTableComponent,
    ReplyComponent,
    SelectConversationComponent,
    PostMessageDialogComponent,
    ConversationEditParticipantListComponent,
    ConversationEditSpectatorListComponent,
    ConversationEditExtraListComponent,
    ConversationEditExtraInListComponent,
    WialonComponent,
    HasPermissionDirective,
    LinkUserComponent,
    TrialComponent,
    TrialUsersComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ClipboardModule,
        ImageViewerModule,
        PushNotificationsModule,

        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatDividerModule,
        MatToolbarModule,
        MatMenuModule,
        MatListModule,
        MatRippleModule,
        MatBadgeModule,
        MatChipsModule,
        MatSnackBarModule,
        MatTableModule,
        MatDialogModule,
        MatProgressBarModule,
        MatButtonToggleModule,
        MatTooltipModule,
        MatSidenavModule,
        MatDatepickerModule,
        MatSelectModule,
        MatSliderModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatSortModule,
      MatCheckboxModule,
    ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3000}},
    AuthUserGuard, AuthRolesGuard, AuthUserWithRolesGuard, AnonymousGuard,
    UserRolePipe,
    SessionService, AuthService, HttpService, ConversationService, MessageService, StatusService, AudioService,
    InfoService, TrialService,
  ],
  entryComponents: [ConfirmationComponent, DialogFileDialogComponent, DialogConversationViewImageComponent,
    GroupNameComponent, GroupParticipantsComponent, GroupDelParticipantsComponent, GroupAddParticipantsComponent,
    DialogConversationMessageInfoComponent, DialogConversationMessageForwardComponent, SpectatorsComponent,
    HistoryVoiceComponent, AddRoleNameComponent, EditMemberNameComponent, BatchComponent, ParticipantsComponent,
    ReplyComponent, SelectConversationComponent, PostMessageDialogComponent, LinkUserComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
