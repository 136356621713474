export enum StatusType {
  MESSAGES_NEW = 'MESSAGES_NEW',
  MESSAGES_STATUS_UPDATED = 'MESSAGES_STATUS_UPDATED',
  PARTICIPANT_STATUS_UPDATED = 'PARTICIPANT_STATUS_UPDATED',
  PUSH_MESSAGES_NEW = 'PUSH_MESSAGES_NEW',
  SPECTATOR_MESSAGES_NEW = 'SPECTATOR_MESSAGES_NEW',
  CONVERSATION_NEW = 'CONVERSATION_NEW',
  CONVERSATION_DELETED = 'CONVERSATION_DELETED',
  VERSION_NEW = 'VERSION_NEW',
  LOG_REQUEST = 'LOG_REQUEST'
}
