import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConversationBean, MessageBean} from "@chat/common-model";
import {ConversationService} from "../../../service/conversation.service";

@Component({
  selector: 'app-reply',
  templateUrl: './reply.component.html',
  styleUrls: ['./reply.component.scss']
})
export class ReplyComponent implements OnInit {

  replyTo: MessageBean;
  conversation: ConversationBean;

  constructor(private conversationService: ConversationService,
              public dialogRef: MatDialogRef<ReplyComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    const replyTo = JSON.parse(JSON.stringify(data.replyTo));
    replyTo.replyTo = null;
    this.replyTo = replyTo;
  }

  ngOnInit() {
    this.conversationService.get(this.replyTo.conversation.id).subscribe((conversation) => {
      this.conversation = conversation;
    })
  }

  onClickClose() {
    this.dialogRef.close();
  }

  onSentClick() {
    this.dialogRef.close(this.replyTo);
  }
}
