import {Component, Inject, OnInit} from '@angular/core';
import {
  ConversationBean, UserInfoBean, UserConnectionType, MessageStatusType, MessagePushStatusType
} from "@chat/common-model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ConversationService} from "../../../../../service/conversation.service";
import {ConfirmationComponent} from "../../../../../common/confirmation/confirmation.component";

@Component({
  selector: 'app-dialog-conversation-message-forward',
  templateUrl: './dialog-conversation-message-forward.component.html',
  styleUrls: ['./dialog-conversation-message-forward.component.scss']
})
export class DialogConversationMessageForwardComponent implements OnInit {

  conversations: ConversationBean[] = [];
  conversationsLoaded: boolean = false;
  selected: ConversationBean;
  participants: UserInfoBean[] = [];

  UserConnectionType: any = UserConnectionType;
  MessageStatusType: any = MessageStatusType;
  MessagePushStatusType: any = MessagePushStatusType;

  userId: string;

  constructor(public dialogRef: MatDialogRef<DialogConversationMessageForwardComponent>,
              private conversationService: ConversationService,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.getConversations();
  }

  getConversations() {
    this.conversationService.getConversationList().subscribe((list: ConversationBean[]) => {
      this.conversations = list;
      this.sort();
    }, () => {}, () => { this.conversationsLoaded = true; });
  }

  sort() {
    this.conversations.sort((a, b) => {
      const nameA = a.name ? a.name.toUpperCase() : '';
      const nameB = b.name ? b.name.toUpperCase() : '';
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  onClickSelectConversation(conversation: ConversationBean) {
    this.selected = conversation;
  }

  onClickSelect() {
    if (this.selected) {
      const config = new MatDialogConfig();
      config['data'] = {
        title: 'Предупреждение',
        message: 'Вы уверены, что хотите переслать сообщение в чат: ' + this.selected.name + "?",
        ok: 'Переслать'
      };
      const confirmationDialogRef = this.dialog.open(ConfirmationComponent, config);
      confirmationDialogRef.afterClosed().subscribe(result => {
        if (result && result['data'] === 'ok') {
          this.dialogRef.close(this.selected);
        }
      });
    }
  }
}
