
export interface Device {
  application?: string,
  android?: string,
  model?: string
}

export enum UserStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  DELETED = 'DELETED',
}

export enum UserRole {
  DRIVER = 'DRIVER',
  OPERATOR = 'OPERATOR',
  MECHANIC = 'MECHANIC',
  SUPERVISOR = 'SUPERVISOR',
  ADMIN = 'ADMIN',
  SUPERADMIN = 'SUPERADMIN'
}

export enum UserExternalType {
  WIALON = 'WIALON',
}

export enum UserConnectionType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE'
}
