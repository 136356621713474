import {Pipe, PipeTransform} from '@angular/core';
import {UserRolePipe} from "./user-role.pipe";
import {ConversationBean, UserInfoBean} from "@chat/common-model";

@Pipe({
  name: 'participantInfo'
})
export class ParticipantInfoPipe implements PipeTransform {

  constructor(private userRolePipe: UserRolePipe) {
  }

  transform(value: UserInfoBean, ...args: any[]): any {
    return this.userRolePipe.transform(value.role) + ": " + value.contactName;
  }

}
