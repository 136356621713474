import {Component, OnInit} from '@angular/core';
import {Url} from "../../common/url";
import {Router} from "@angular/router";
import {SessionService} from "../../common/auth/session.service";
import {UserRole} from "@chat/common-model";

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent implements OnInit {

  constructor(private router: Router, private sessionService: SessionService) {
  }

  ngOnInit() {
  }

  onClickGoHome() {
    let route;
    if (this.sessionService.user) {
      const {role} = this.sessionService.user;
      route = Url.getRouteByRole(role);
    } else {
      route = Url.ROUTE_SIGNIN;
    }

    this.router.navigate([route]);
  }
}
