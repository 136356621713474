import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ConversationBean, UserBean, UserRole} from "@chat/common-model";
import {UserService} from "../../../../../service/admin/user.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-spectators',
  templateUrl: './spectators.component.html',
  styleUrls: ['./spectators.component.scss']
})
export class SpectatorsComponent implements OnInit {

  usersLoaded: boolean = false;
  allUsers: UserBean[];
  conversation: ConversationBean;

  @ViewChild('selectedForGroupConversation', { static: false }) selectedForGroupConversation: any;

  constructor(public dialogRef: MatDialogRef<SpectatorsComponent>,
              private userService: UserService,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.conversation = data.conversation;
  }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.userService.getListByCompany(this.data.companyId).subscribe((users: UserBean[]) => {
      this._getUsers(users);
    })
  }

  _getUsers(users: UserBean[]) {
    this.allUsers = users.filter(u => [UserRole.OPERATOR].includes(u.role)
      && !(this.conversation.participants && this.conversation.participants.find(p => p.id === u.id))
      && !(this.conversation.spectators && this.conversation.spectators.find(p => p.id === u.id)));
    this.usersLoaded = true;
  }

  onClickCreateConversation(list: any) {
    if (list && list.selectedOptions && list.selectedOptions.selected.length > 0) {
      let participants = list.selectedOptions.selected.map(selected => selected.value);
      this.dialogRef.close(participants);
    }
  }

}
