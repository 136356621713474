import { Pipe, PipeTransform } from '@angular/core';
import {UserStatus} from "@chat/common-model";

@Pipe({
  name: 'userStatus'
})
export class UserStatusPipe implements PipeTransform {

  transform(value: UserStatus, ...args: any[]): any {
    let text;
    switch (value) {
      case UserStatus.ACTIVE:
        text = "Активный";break;
      case UserStatus.BLOCKED:
        text = "Заблокирован";break;
     case UserStatus.DELETED:
        text = "Удален";break;
      default:
        text = "";
    }
    return text;
  }

}
