import { Pipe, PipeTransform } from '@angular/core';
import {UserRole} from "@chat/common-model";

@Pipe({
  name: 'userRoleToIcon'
})
export class UserRoleToIconPipe implements PipeTransform {

  transform(value: UserRole, ...args: any[]): string {
    let text;
    switch (value) {
      case UserRole.DRIVER:
        text = "local_shipping";break;
      case UserRole.OPERATOR:
        text = "computer";break;
      case UserRole.MECHANIC:
        text = "build";break;
      case UserRole.SUPERVISOR:
        text = "supervised_user_circle";break;
      case UserRole.ADMIN:
        text = "supervisor_account";break;
      case UserRole.SUPERADMIN:
        text = "all_inclusive";break;
      default:
        text = "help_outline";
    }
    return text;
  }

}
