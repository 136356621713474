import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameToFio'
})
export class NameToFioPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    const value1 = value || "-";
    const vs = value1.toUpperCase().split(" ");
    return vs.map(v => v.substr(0,1)).join("").substr(0, 3);
  }

}
