import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ParticipantBean} from "@chat/common-model";

@Component({
  selector: 'app-edit-member-name',
  templateUrl: './edit-member-name.component.html',
  styleUrls: ['./edit-member-name.component.scss']
})
export class EditMemberNameComponent implements OnInit {

  member: ParticipantBean;
  name: string;

  constructor(public dialogRef: MatDialogRef<EditMemberNameComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ParticipantBean) {
    this.member = data;
    this.name = this.member.conversationName;
  }

  ngOnInit() {
  }

  onClickEditName() {
    if (this.name) {
      this.dialogRef.close(this.name);
    }
  }

  onClickDeleteName() {
    this.dialogRef.close(null);
  }
}
