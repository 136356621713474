import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ConversationBean, MessageBean, MessageListBean, UserInfoBean, UserConnectionType,
  ConversationType, MessageStatusType, MessagePushStatusType, MessageDirectionType
} from "@chat/common-model";
import {SessionService} from "../../../../common/auth/session.service";
import {ConversationService} from "../../../../service/conversation.service";
import {MessageService} from "../../../../service/message.service";
import {UserService} from "../../../../service/admin/user.service";
import {StatusService} from "../../../../service/status.service";
import * as moment from "moment";

@Component({
  selector: 'app-table-conversation-list',
  templateUrl: './table-conversation-list.component.html',
  styleUrls: ['./table-conversation-list.component.scss']
})
export class TableConversationListComponent implements OnInit, OnDestroy {

  conversations: ConversationBean[] = [];
  conversationsLoaded: boolean = false;
  currentId: string;
  participants: UserInfoBean[] = [];

  UserConnectionType: any = UserConnectionType;
  ConversationType: any = ConversationType;
  MessageStatusType: any = MessageStatusType;
  MessagePushStatusType: any = MessagePushStatusType;

  userId: string;
  conversationType: MessageDirectionType;
  MessageDirectionType: any = MessageDirectionType;

  constructor(private sessionService: SessionService,
              private conversationService: ConversationService,
              private messageService: MessageService,
              private userService: UserService,
              private statusService: StatusService) {
  }

  ngOnInit() {
    this.userId = this.sessionService.user.id;
    this.getConversations();
    this.conversationService.currentConversationEmitter.subscribe((current: ConversationBean) => {
      this.currentId = current ? current.id : null;
    });
    this.statusService.newMessagesEmitter.subscribe((messageList: MessageListBean) => {
      const conversation = this._findConversation(messageList.conversation.id);
      if (conversation) {
        if (messageList.conversation.id !== this.currentId) {
          conversation.unreadCount = isNaN(conversation.unreadCount) ? messageList.messages.length : conversation.unreadCount + messageList.messages.length;
        }
        let lastMessage: MessageBean = conversation.lastMessage;
        messageList.messages.forEach(m => {
          if (!lastMessage || moment.utc(m.created).isAfter(moment.utc(lastMessage.created))) {
            lastMessage = m;
          }
        });
        if (lastMessage) {
          conversation.lastMessage = lastMessage;
        }
        this.sort();
      }
      this.messageService.play();
    });
    this.messageService.postMessageEmitter.subscribe((message: MessageBean) => {
      const conversation = this._findConversation(message.conversation.id);
      if (conversation) {
        conversation.lastMessage = message;
      }
    });
    this.statusService.statusUpdatedMessagesEmitter.subscribe((messageList: MessageListBean) => {
      const conversation = this._findConversation(messageList.conversation.id);
      if (conversation && conversation.lastMessage) {
        if (messageList.messages) {
          const message = messageList.messages.find(m => m.id === conversation.lastMessage.id);
          if (message) {
            conversation.lastMessage.status = message.status;
            conversation.lastMessage.pushStatus = message.pushStatus;
            conversation.lastMessage.pushed = message.pushed;
          }
        }
      }
    });
    this.userService.conversationCreatedEmitter.subscribe(() => {
      this.getConversations();
    });
  }

  _findConversation(conversationId: string): ConversationBean {
    return this.conversations.find(c => c.id === conversationId ||(c.extraConversations && c.extraConversations[this.userId] && c.extraConversations[this.userId].find(ec => ec.id === conversationId)));
  }

  ngOnDestroy(): void {

  }

  getConversations() {
    this.conversationService.getConversationList().subscribe((list: ConversationBean[]) => {
      const extraConversationIds: string[] = [];
      list.forEach(c => {
        if (c.participants) {
          c.participants.forEach(p => {
            if (!this.participants.find(ps => ps.id === p.id)) {
              this.participants.push(p);
            }
          })
        }
        if (c.extraConversations && c.extraConversations[this.userId]) {
          for (const extraConversation of c.extraConversations[this.userId]) {
            extraConversationIds.push(extraConversation.id);
          }
        }
      });
      if (extraConversationIds.length > 0) {
        list = list.filter(l => !extraConversationIds.includes(l.id))
      }
      this.conversations = list;
      this.sort();
    }, () => {
    }, () => {
      this.conversationsLoaded = true;
    });
  }

  onClickSelectConversationType(type: MessageDirectionType) {
    this.conversationType = type;
    this.messageService.typeMessageEmitter.emit(type);
    this.conversationService.currentConversationEmitter.emit(null);
  }

  onClickSelectConversation(conversation: ConversationBean) {
    this.conversationType = null;
    this.conversationService.selectCurrentConversation(conversation);
  }

  sort() {
    this.conversations.sort((a, b) => {
      const aName = a.name ? a.name.toUpperCase() : '';
      const bName = b.name ? b.name.toUpperCase() : '';
      if (aName > bName) {
        return 1;
      }
      if (bName > aName) {
        return -1;
      }
      return 0;
    });
  }
}
