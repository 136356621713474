import {AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConversationBean} from "@chat/common-model";
import {ConversationService} from "../../../service/conversation.service";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSelectionList, MatSelectionListChange} from "@angular/material/list";
import {Subscription} from "rxjs";
import {SessionService} from "../../../common/auth/session.service";

interface ConversationBeanSelectable extends ConversationBean {
  selected?: boolean;
}

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss']
})
export class BatchComponent implements OnInit {

  conversationsLoaded: boolean = false;
  allConversations: ConversationBean[];
  step: number = 1;

  conversations: ConversationBean[];
  filteredConversations: ConversationBeanSelectable[];
  selectedConversations: ConversationBean[] = [];

  search: string;

  @ViewChild('selectedForGroupConversation', { static: false }) selectedForGroupConversation: MatSelectionList;
  selectionChangeSubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<BatchComponent>,
              private sessionService: SessionService,
              private conversationService: ConversationService) { }

  ngOnInit() {
    this.getConversations();

  }

  getConversations() {
    this.conversationService.getConversationList().subscribe((conversations: ConversationBean[]) => {
      this._getConversations(conversations);
    })
  }

  _getConversations(conversations: ConversationBean[]) {
    this.allConversations = conversations.filter(c => !(c.spectators && c.spectators.find(p => p.id === this.sessionService.user.id)));
    this.filteredConversations = this.allConversations;
    this.conversationsLoaded = true;
  }

  onClickNext(list: any) {
    if (list && list.selectedOptions && list.selectedOptions.selected.length > 0) {
      const conversations = list.selectedOptions.selected.map(selected => selected.value);
      this.conversations = conversations;
      this.step = 2;
    }
  }

  onClickBack() {
    this.step = 1;
  }

  getConversationNames() {
    return this.conversations.map(c => c.name).join(", ");
  }

  onMessageSent() {
    this.dialogRef.close();
  }

  onClickSelectAll() {
    this.selectedForGroupConversation.selectAll();
    this.filteredConversations.forEach(fc => {
      if (!this.selectedConversations.find(sc => sc.id === fc.id)) {
        this.selectedConversations.push(fc);
      }
    });
  }
  onClickDeselectAll() {
    this.selectedForGroupConversation.deselectAll();
    this.filteredConversations.forEach(fc => {
      this.selectedConversations = this.selectedConversations.filter(sc => sc.id !== fc.id);
    });
  }

  onClickClearSearch() {
    this.search = null;
    this.onChangeSearch();
  }

  onChangeSearch() {
    if (this.search) {
      this.filteredConversations = this.allConversations.filter(option => new RegExp(this.search, 'gi')
        .test(option.name));
    } else {
      this.filteredConversations = this.allConversations;
    }
    this.selectedConversations.forEach(sc => {
      const conversation = this.filteredConversations.find(fc => fc.id === sc.id);
      if (conversation) conversation.selected = true;
    });
  }

  onSelectionChange($event: MatSelectionListChange) {
    if ($event && $event.option) {
      const conversation: ConversationBean = $event.option.value;
      if ($event.option.selected && !this.selectedConversations.find(c => c.id === conversation.id)) {
        this.selectedConversations.push(conversation);
      } else if (!$event.option.selected && this.selectedConversations.find(c => c.id === conversation.id)) {
        this.selectedConversations = this.selectedConversations.filter(c => c.id !== conversation.id);
      }
    }

  }
}
