import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatTable} from "@angular/material";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {
  ConversationBean,
  ConversationType,
  Filter,
  FilterMethod,
  MessageBean,
  MessageListBean,
  MessageType, ParticipantBean,
  UserBean,
  MessageStatusType,
  MessagePushStatusType
} from "@chat/common-model";
import {UserService} from "../../../service/admin/user.service";
import {MessageFileType, MessageService} from "../../../service/message.service";
import {ConversationService} from "../../../service/conversation.service";
import {Observable} from "rxjs";
import {DialogConversationViewImageComponent} from "../../chat/dialog-conversation/dialog-conversation-view-image/dialog-conversation-view-image.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HistoryVoiceComponent} from "./voice/history-voice.component";
import {map, startWith} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocomplete, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {ActivatedRoute} from "@angular/router";

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD-MM-YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
  ]
})
export class HistoryComponent implements OnInit {

  companyId: string = null;

  startDate: moment.Moment;
  endDate: moment.Moment;

  users: UserBean[] = [];
  filteredUsers: Observable<UserBean[]>;
  loadedUsers: UserBean[] = [];
  userCtrl = new FormControl();
  @ViewChild('userInput', {static: true}) userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: true}) matAutocomplete: MatAutocomplete;


  messages: MessageBean[] = [];
  @ViewChild(MatTable, {static: true}) table: MatTable<MessageBean>;
  from: string;
  messageDisplayedColumns: string[] = ['id', 'status', 'author', 'participant', 'created', 'message', 'attach'];
  showMoreButton: boolean = false;

  conversations: ConversationBean[] = [];

  MessageType: any = MessageType;
  MessageStatusType: any = MessageStatusType;
  MessagePushStatusType: any = MessagePushStatusType;

  loading: boolean = false;

  constructor(private userService: UserService,
              private conversationService: ConversationService,
              private messageService: MessageService,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) {
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(null),
      map((userText: string | null) => userText ? this._filter(userText) : this.loadedUsers.slice()));
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.companyId = params["companyId"];
      this._resetFrom();
      this.getUsers();
    });
  }

  getUsers() {
    this.userService.getList(this.companyId).subscribe((list: UserBean[]) => {
      this.loadedUsers = list;
      this.getMessages();
    })
  }

  getMessages(reset: boolean = false) {
    if (reset) {
      this._resetFrom();
    }
    this.messageService.getAllMessageList(this.companyId, this.from, 20, this.messageService.filters).subscribe((list: MessageListBean) => {
      if (reset) {
        this.messages = [];
      }
      if (list) {
        list.messages.forEach(mb => {
          const author = this.loadedUsers.find(u => u.id === mb.author.id);
          if (author) {
            mb.author = author;
          }
          this._getParticipant(mb).subscribe(p => {
            mb["participant"] = p;
          });
          if (mb.message) {
            mb.message = mb.message.replace(/(?:\r\n|\r|\n)/g, '<br />');
          }
          this.messages.push(mb);
        });
        this.from = list.messages[list.messages.length - 1].created;
        this.showMoreButton = list.hasMore;
        this.table.renderRows();
      }
    })
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.userCtrl.setValue(null);
  }

  remove(user: UserBean): void {
    const index = this.users.findIndex(u => u.id === user.id);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.users.push(event.option.value);
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);
  }

  private _filter(value: string): UserBean[] {
    if (value && typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.loadedUsers.filter(user => (user.fullName + ' ' + user.contactName).toLowerCase().indexOf(filterValue) > -1);
    } else {
      return this.loadedUsers;
    }
  }

  onClickApply() {
    const {filters} = this.messageService;
    filters.filters = [];

    if (this.startDate) {
      const startDate = moment.isMoment(this.startDate) ? moment(this.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z' : null;
      filters.filters.push(Filter.getInstance("from", startDate, FilterMethod.MORE_EQUALS));
    }
    if (this.endDate) {
      const endDate = moment.isMoment(this.endDate) ? moment(this.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z' : null;
      filters.filters.push(Filter.getInstance("to", endDate, FilterMethod.LESS_EQUALS));
    }
    if (this.users && this.users.length > 0) {
      filters.filters.push(Filter.getInstance("users", this.users.map(u => u.id).join(Filter.IN_DELIMETER), FilterMethod.IN));
    }
    this.getMessages(true);
  }

  onClickReset() {
    this.startDate = null;
    this.endDate = null;
    this.users = [];
    const {filters} = this.messageService;
    filters.filters = [];
  }

  _resetFrom() {
    this.from = moment().utc().toISOString();
  }

  _getParticipant(message: MessageBean): Observable<string> {
    return new Observable<string>(observer => {
      let c = this.conversations.find(c => c.id === message.conversation.id);
      let participant: ParticipantBean;
      if (c) {
        if (c.type === ConversationType.GROUP) {
          observer.next(c.name || "Группа");
        } else {
          participant = c.participants.find(p => p.id !== message.author.id);
          if (participant) {
            const user = this.loadedUsers.find(u => u.id === participant.id);
            if (user) {
              observer.next(user.contactName);
            } else {
              observer.next("");
            }
          } else {
            observer.next("");
          }
        }
        observer.complete();
      } else {
        this.conversationService.get(message.conversation.id).subscribe((conversation: ConversationBean) => {
          this.conversations.push(conversation);
          if (conversation.type === ConversationType.GROUP) {
            observer.next(conversation.name || "Группа");
          } else {
            participant = conversation.participants.find(p => p.id !== message.author.id);
            if (participant) {
              const user = this.loadedUsers.find(u => u.id === participant.id);
              if (user) {
                observer.next(user.contactName);
              } else {
                observer.next("");
              }
            } else {
              observer.next("");
            }
          }
          observer.complete();
        });
      }
    });
  }

  onClickResource(message: MessageBean) {
    if (message.url) {
      if (message.type === MessageType.FILE) {
        this.loading = true;
        this.messageService.getFile(message).subscribe(result => {
          this.loading = false;
          const link = document.createElement('a');
          link.style.display = 'none';
          document.body.appendChild(link);


          const blob = new Blob([result], {type: this.messageService.getFileMimeType(message)});
          link.href = URL.createObjectURL(blob);
          link.download = message.originalName || message.id;
          link.click();
        }, () => {
          this.loading = false;
          this.snackBar.open("Ошибка загрузки файла");
        });
      } else if (message.type === MessageType.IMAGE) {
        this.loading = true;
        this.messageService.getFile(message).subscribe(resultJpeg => {
          this.loading = false;
          this._openImage(message, resultJpeg, "image/jpeg");
        }, err => {
          this.messageService.getFile(message, MessageFileType.ORIGIN).subscribe(result => {
            this.loading = false;
            this._openImage(message, result, message.mimeType);
          }, () => {
            this.loading = false;
            this.snackBar.open("Ошибка загрузки изображения");
            }, () => { this.loading = false; });
        });
      } else if (message.type === MessageType.VOICE) {
        this._openVoice(message);
      }
    }
  }

  onClickDownloadResource(message: MessageBean) {
    if (message.url) {
      this.loading = true;
      this.messageService.getFile(message).subscribe(result => {
        this.loading = false;
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        const blob = new Blob([result], {type: this.messageService.getFileMimeType(message)});
        link.href = URL.createObjectURL(blob);
        link.download = message.originalName || message.id;
        link.click();
      }, () => {
        this.loading = false;
        this.snackBar.open("Ошибка загрузки файла");
      });
    }
  }

  onClickShowMore() {
    this.getMessages();
  }

  private _openImage(message: MessageBean, image, mimeType) {
    const config = new MatDialogConfig();
    config['data'] = {message: message, image: new Blob([image], {type: mimeType}), type: mimeType};
    config.height = '100%';
    config.width = '100%';
    config.maxHeight = '100%';
    config.maxWidth = '100%';

    config.panelClass = 'transparent';
    const dialogRef = this.dialog.open(DialogConversationViewImageComponent, config);
  }

  private _openVoice(message: MessageBean) {
    const config = new MatDialogConfig();
    config['data'] = {message: message};

    config.panelClass = 'transparent';
    const dialogRef = this.dialog.open(HistoryVoiceComponent, config);
  }
}
