import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'msgIdToShortText'
})
export class MsgIdToShortTextPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    const parts = value.split('-');
    return '...' + parts[parts.length-1];
  }

}
