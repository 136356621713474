import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SessionService} from "../../../../common/auth/session.service";
import {
  ConversationBean,
  ConversationType,
  MessageBean,
  MessagePushStatusType,
  MessageStatusType,
  MessageType,
  UserRole
} from "@chat/common-model";
import {MessageFileType, MessageService} from "../../../../service/message.service";
import {MatDialog, MatDialogConfig} from "@angular/material";
import {DialogConversationViewImageComponent} from "../dialog-conversation-view-image/dialog-conversation-view-image.component";
import {DomSanitizer} from "@angular/platform-browser";
import {MatMenuTrigger} from "@angular/material/menu";
import {DialogConversationMessageInfoComponent} from "./info/dialog-conversation-message-info.component";
import {ClipboardService} from "ngx-clipboard";
import {ConversationService} from "../../../../service/conversation.service";
import {DialogConversationMessageForwardComponent} from "./forward/dialog-conversation-message-forward.component";
import {ReplyComponent} from "../../reply/reply.component";
import {UserService} from "../../../../service/admin/user.service";


@Component({
  selector: 'app-dialog-conversation-message',
  templateUrl: './dialog-conversation-message.component.html',
  styleUrls: ['./dialog-conversation-message.component.scss']
})
export class DialogConversationMessageComponent implements OnInit {

  @Input("message") message: MessageBean;
  @Input("conversation") conversation: ConversationBean;
  @Input("contextMenu") contextMenu: boolean = true;
  personal: boolean;
  MessageStatusType: any = MessageStatusType;
  MessagePushStatusType: any = MessagePushStatusType;
  MessageType: any = MessageType;
  ConversartionType: any = ConversationType;

  preview: any;
  audioUrl: any;

  spectator: boolean = false;
  extra: boolean = false;
  hasExtra: boolean = false;

  @ViewChild(MatMenuTrigger, {static: false}) trigger: MatMenuTrigger;

  constructor(private sanitizer: DomSanitizer,
              private dialog: MatDialog,
              private clipboardService: ClipboardService,
              private sessionService: SessionService,
              private userService: UserService,
              private conversationService: ConversationService,
              private messageService: MessageService) {
  }

  ngOnInit() {
    const {user} = this.sessionService;
    this.personal = user.id === this.message.author.id;
    this.spectator = this.conversationService.isSpectator(this.conversation);
    this.hasExtra = !!this.conversation && !!this.conversation.extraConversations && !!this.conversation.extraConversations[user.id] && this.conversation.extraConversations[user.id].length > 0;
    this.extra = !!this.conversation && !!this.conversation.extraConversations && !!this.conversation.extraConversations[user.id]
      && !!this.conversation.extraConversations[user.id].find(ec => ec.id === this.message.conversation.id);

    if (this.message.type === MessageType.IMAGE) {
      this.messageService.getFile(this.message, MessageFileType.THUMBNAIL).subscribe(result => {
        function _arrayBufferToBase64(buffer) {
          var binary = '';
          var bytes = new Uint8Array(buffer);
          var len = bytes.byteLength;
          for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          return window.btoa(binary);
        }
        this.preview = "data:image/jpeg;base64," + _arrayBufferToBase64(result);
      }, () => {
        this.onImageError(this.message);
      });
    } else if (this.message.type === MessageType.VOICE) {
      this.messageService.getFile(this.message).subscribe(result => {
        const _arrayBufferToUrl = (buffer, type) => {
          const arrayBufferView = new Uint8Array(buffer);
          const blob = new Blob([arrayBufferView], {type: type});
          const urlCreator = window.URL;
          return urlCreator.createObjectURL(blob);
        };
        const createSoundWithBuffer = (buffer) => {
          var context = new AudioContext();
          context.decodeAudioData(buffer, (res) => {
            this.audioUrl = this.sanitizer.bypassSecurityTrustUrl(_arrayBufferToUrl(res, this.message.mimeType));
          });
        };
        //   createSoundWithBuffer(result);
        this.audioUrl = this.sanitizer.bypassSecurityTrustUrl(_arrayBufferToUrl(result, this.message.mimeType));
      });
    }
  }

  onClickDownload(message: MessageBean) {
    if (message.url) {
      this.messageService.getFile(message).subscribe(result => {
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);


        const blob = new Blob([result]);
        link.href = URL.createObjectURL(blob);
        link.download = message.originalName || message.id;
        link.click();
      });
    }
  }

  onClickDownloadImage(message: MessageBean) {
    this.messageService.getFile(message).subscribe(resultJpeg => {
      const data = {message: message, image: new Blob([resultJpeg], {type: "image/jpeg"}), type: "image/jpeg"};

      const link = document.createElement( 'a' );
      link.style.display = 'none';
      document.body.appendChild( link );
      const blob = data.image;
      let type = "";
      if (data.type) {
        const splitType = data.type.split("/");
        if (splitType.length > 1) {
          type = "." + splitType[1];
        }
      }
      link.href = URL.createObjectURL( blob );
      link.download = this.message.id + type;
      link.click();
    });
  }

  onClickOpenImage(message: MessageBean) {
    if (message.url) {
      this.messageService.getFile(message).subscribe(resultJpeg => {
        this._openImage(message, resultJpeg, "image/jpeg");
      }, err => {
        this.messageService.getFile(message, MessageFileType.ORIGIN).subscribe(result => {
          this._openImage(message, result, message.mimeType);
        });
      });
    }
  }

  private _openImage(message: MessageBean, image, mimeType) {
    const config = new MatDialogConfig();
    config['data'] = {message: message, image: new Blob([image], {type: mimeType}), type: mimeType};
    config.height = '100%';
    config.width = '100%';
    config.maxHeight = '100%';
    config.maxWidth = '100%';

    config.panelClass = 'transparent';
    const dialogRef = this.dialog.open(DialogConversationViewImageComponent, config);
  }

  onImageLoad(message: MessageBean) {
    // console.log('onImageLoad');
    this.messageService.imageMessageLoadedEmitter.emit(this.message);
  }

  onImageError(message: MessageBean) {
    // console.log('onImageError');
    this.messageService.imageMessageLoadedEmitter.emit(this.message);
  }

  getAuthorName(message: MessageBean): string {
    const author = this.userService.users.find(u => u.id === message.author.id);
    // const author = this.conversation.participants.find(participant => participant.id === message.author.id);
    return author ? author.contactName : '';
  }

  onRightClick(event: MouseEvent) {
    if (this.contextMenu) {
      this.trigger.openMenu();
      event.preventDefault();
    }
  }

  onClickInfo() {
    const config = new MatDialogConfig();
    config.data = {message: this.message, conversation: this.conversation};
    const dialogRef = this.dialog.open(DialogConversationMessageInfoComponent, config);
  }

  onClickCopy() {
    this.clipboardService.copyFromContent(this.message.message);
  }

  onClickMenu(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onClickForward() {
    const config = new MatDialogConfig();
    config.data = {message: this.message, conversation: this.conversation};
    const dialogRef = this.dialog.open(DialogConversationMessageForwardComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const forwardTo: ConversationBean = result;
        this.messageService.forwardMessage(forwardTo.id, this.message).subscribe((newMessage: MessageBean) => {
          this.conversationService.selectCurrentConversation(forwardTo);
        });
      }
    });
  }

  isOperatorMessage(): boolean {
    return this.spectator && UserRole.OPERATOR === this.message.author.role;
  }

  getMessage(mb: MessageBean) {
    return mb.replyTo ? `<span class="reply-message">${mb.replyTo.message}</span><span>${mb.message}</span>` : mb.message;
  }

  onClickReply() {
    const config = new MatDialogConfig();
    config['data'] = {replyTo: this.message};
    config.width = '600px';

    this.dialog.open(ReplyComponent, config);
  }
}
