
export enum MessageStatusType {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  SEEN = 'SEEN'
}

export enum MessageType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  FILE = 'FILE',
  VOICE = 'VOICE',
  LOCATION = 'LOCATION'
}

export enum MessagePushStatusType {
  UNKNOWN = 'UNKNOWN',
  DELIVERED = 'DELIVERED'
}
