import {AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ConversationService} from "../../../service/conversation.service";
import {MessageService} from "../../../service/message.service";
import {SessionService} from "../../../common/auth/session.service";
import {
  ConversationBean,
  ConversationType,
  DirectionType,
  MessageBean,
  MessageListBean,
  MessageStatusType,
  UserBean
} from "@chat/common-model";
import {StatusService} from "../../../service/status.service";
import * as moment from 'moment';
import {Subscription} from "rxjs";
import {UserService} from "../../../service/admin/user.service";

@Component({
  selector: 'app-dialog-conversation',
  templateUrl: './dialog-conversation.component.html',
  styleUrls: ['./dialog-conversation.component.scss']
})
export class DialogConversationComponent implements OnInit, OnDestroy, AfterViewChecked {

  messages: MessageBean[] = [];
  conversation: ConversationBean;
  user: UserBean;
  needToScrollBottom: boolean = false;
  isLoadHistory: boolean = false;
  hasMoreHistory: boolean = false;
  hasMoreNew: boolean = false;

  currentConversationSubscription: Subscription;
  postMessageSubscription: Subscription;
  statusUpdatedMessagesSubscription: Subscription;
  newMessagesSubscription: Subscription;
  imageMessageLoadedSubscription: Subscription;

  spectator: boolean = false;
  userList: UserBean[] = [];

  windowFocus: boolean = true;

  lastId: string;
  newImageCount: number;

  constructor(private elRef: ElementRef,
              private conversationService: ConversationService,
              private messageService: MessageService,
              private statusService: StatusService,
              private userService: UserService,
              private sessionService: SessionService) {
  }

  ngOnInit() {
    this.user = this.sessionService.user;
    this.currentConversationSubscription = this.conversationService.currentConversationEmitter.subscribe((current: ConversationBean) => {
      this.conversation = current;
      this.spectator = this.conversationService.isSpectator(this.conversation);

      this.messages = [];
      this.needToScrollBottom = false;
      this.isLoadHistory = false;
      this.hasMoreHistory = false;
      this.hasMoreNew = false;

      this.imageMessageLoadedSubscription && this.imageMessageLoadedSubscription.unsubscribe();
      this.imageMessageLoadedSubscription = this.messageService.imageMessageLoadedEmitter.subscribe((message: MessageBean) => {
        this.needToScrollBottom = true;
        this.scroll();
      });

      if (this.spectator) {
        this.userService.getList().subscribe((userList: UserBean[]) => {
          this.userList = userList;
          this.loadHistory(true);
        });
      } else {
        this.loadHistory(true);
      }
    });
    this.postMessageSubscription = this.messageService.postMessageEmitter.subscribe((message: MessageBean) => {
      if (this.conversation && message.conversation && message.conversation.id === this.conversation.id) {
        if (message.message) {
          message.message = message.message.replace(/(?:\r\n|\r|\n)/g, '<br />');
        }
        this.messages.push(message);
        this.needToScrollBottom = true;
      }
    });
    this.statusUpdatedMessagesSubscription = this.statusService.statusUpdatedMessagesEmitter.subscribe((messageList: MessageListBean) => {
      if (messageList && messageList.conversation && this.conversation && messageList.conversation.id === this.conversation.id) {
        if (messageList.messages) {
          messageList.messages.forEach(nsm => {
            const message = this.messages.find(m => m.id === nsm.id);
            if (message) {
              message.status = nsm.status;
              message.groupStatus = nsm.groupStatus;
              message.pushStatus = nsm.pushStatus;
              message.pushed = nsm.pushed;
            }
          });
        }
      }
    });
    this.newMessagesSubscription = this.statusService.newMessagesEmitter.subscribe((messageList: MessageListBean) => {
      if (this._isCurrentConversation(messageList.conversation.id)) {
        if (messageList.messages) {
          for (const message of messageList.messages) {
            this.addMessage(message);
          }
          //this.messages = this.messages.concat(messageList.messages);
          this.sortMessages();
          this.needToScrollBottom = true;
          if (!this.spectator && this.windowFocus
            && !(this.conversation.extraConversations && this.conversation.extraConversations[this.user.id]
            && this.conversation.extraConversations[this.user.id].find(ec => ec.id === messageList.conversation.id))) {
            const filtered = this.filterMessagesForSeen(messageList.messages);
            if (filtered && filtered.length > 0) {
              this.messageService.setSeen(this.conversation.id, filtered).subscribe();
            }
          }
        }
      }
    });
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.windowFocus = true;
    if (!this.spectator) {
      const filtered = this.filterMessagesForSeen(this.messages);
      if (filtered && filtered.length > 0) {
        this.messageService.setSeen(this.conversation.id, filtered).subscribe();
      }
    }
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.windowFocus = false;
  }

  _isCurrentConversation(conversationId: string): boolean {
    if (this.conversation
      && (conversationId === this.conversation.id || (this.conversation.extraConversations && this.conversation.extraConversations[this.user.id]
          && this.conversation.extraConversations[this.user.id].find(ec => ec.id === conversationId)))) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.currentConversationSubscription && this.currentConversationSubscription.unsubscribe();
    this.postMessageSubscription && this.postMessageSubscription.unsubscribe();
    this.statusUpdatedMessagesSubscription && this.statusUpdatedMessagesSubscription.unsubscribe();
    this.newMessagesSubscription && this.newMessagesSubscription.unsubscribe();
    this.imageMessageLoadedSubscription && this.imageMessageLoadedSubscription.unsubscribe();
  }

  ngAfterViewChecked(): void {
    // console.log("ngAfterViewChecked");
    this.scroll();
  }

  scroll(): void {
    // console.log("scroll");
    if (this.needToScrollBottom) {
      this.elRef.nativeElement.scrollTop = this.elRef.nativeElement.scrollHeight;
      this.elRef.nativeElement.onscroll = this.scrollHandler.bind(this);
      this.needToScrollBottom = false;
    } else if (this.lastId) {
      // console.log("lastId");
      const element = document.getElementById(this.lastId);
      element.scrollIntoView();
    }
  }

  scrollHandler(event) {
    // console.log("scrollHandler");
    if (event.target.scrollTop < 30 && this.hasMoreHistory && !this.isLoadHistory) {
      this.imageMessageLoadedSubscription && this.imageMessageLoadedSubscription.unsubscribe();
      this.loadHistory(false);
    }
  }

  loadHistory(needToScrollBottom: boolean) {
    if (this.conversation) {
      this.isLoadHistory = true;
      const from = this.messages.length > 0 ? this.messages[0].created : null;
      this.messageService.getMessageList(this.conversation.id, DirectionType.BEFORE, from).subscribe((list: MessageListBean) => {
        if (this.messages && this.messages[0]) {
          this.lastId = this.messages[0].id;
          setTimeout(() => {
            this.isLoadHistory = false;
            this.lastId = null;
          }, 1500);
        } else {
          this.isLoadHistory = false;
          this.lastId = null;
        }
        if (this.messages) {
          list.messages = list.messages.filter(m => !this.messages.find(mm => mm.id === m.id));
        }
        list.messages.forEach(m => {
          const author = this.userList.find(u => u.id === m.author.id);
          m.author.role = author ? author.role : null;
          if (m.message) {
            m.message = m.message.replace(/(?:\r\n|\r|\n)/g, '<br />');
          }
        });
        this.messages = list.messages.reverse().concat(this.messages);
        this.hasMoreHistory = list.hasMore;
        if (!this.spectator && this.windowFocus) {
          const filtered = this.filterMessagesForSeen(list.messages);
          if (filtered && filtered.length > 0) {
            this.messageService.setSeen(this.conversation.id, filtered).subscribe(() => {
              this.statusService.seenMessagesEmitter.emit({
                conversation: this.conversation,
                messages: filtered,
              });
            });
          }
        }
        this.needToScrollBottom = needToScrollBottom;
      })
    }
  }

  loadNew() {
    if (this.conversation) {
      this.isLoadHistory = true;
      const from = this.messages.length > 0 ? this.messages[this.messages.length - 1].created : null;
      this.messageService.getMessageList(this.conversation.id, DirectionType.AFTER, from).subscribe((list: MessageListBean) => {
        this.messages = this.messages.concat(list.messages);
        this.isLoadHistory = false;
        this.hasMoreNew = list.hasMore;
        this.needToScrollBottom = true;
      })
    }
  }

  insertMessage(message: MessageBean) {
  }

  sortMessages() {
    this.messages.sort((a, b) => {return moment(a.created).valueOf() - moment(b.created).valueOf()})
  }

  addMessage(message: MessageBean) {
    if (this.messages.find(m => m.id === message.id) === undefined) {
      if (message.message) {
        message.message = message.message.replace(/(?:\r\n|\r|\n)/g, '<br />');
      }
      this.messages.push(message)
    }
  }

  private filterMessagesForSeen(messages: MessageBean[]): MessageBean[] {
    if (!this.conversation) {
      return [];
    }
    return this.conversation.type === ConversationType.GROUP ?
      messages.filter(m => [MessageStatusType.SENT, MessageStatusType.DELIVERED].includes(m.groupStatus[this.user.id]) && m.author.id !== this.user.id && m.conversation.id === this.conversation.id)
      :
      messages.filter(m => [MessageStatusType.SENT, MessageStatusType.DELIVERED].includes(m.status) && m.author.id !== this.user.id && m.conversation.id === this.conversation.id);
  }

}
